export const DisplaySymbol = Symbol('Display')
/**
  Adds [DisplaySymbol]: {
    '0':        'Enum key one',
    EnumKey1:   'Enum key one',
    '1':        'Enum key etc',
    EnumKeyEtc: 'Enum key etc',
  } to the enum so it can be cleanly displayed to the user.
*/
// eslint-disable-next-line no-unused-vars
function addDisplays(obj, ...args) {
  const d = (obj[DisplaySymbol] = {})
  const entries = Object.entries(obj)
  for (let i = 0; i < args.length; i += 2) {
    const value = args[i]
    const key = entries.find(e => e[1] === value)[0]
    const displayName = args[i + 1]
    d[value] = displayName
    d[key] = displayName
  }
}
/** See source definition here: CN.Infrastructure\DynamicQuery\Match\Filters\Base\MatchAddressFilterBase.cs */
export const AddressSearchMode = {
  /** Simple = 0 */
  Simple: 0,
  /** Advanced = 1 */
  Advanced: 1,
}
/** See source definition here: CN.Infrastructure\Features\Agreements\AgreementListRequest.cs */
export const AgreementListProperty = {
  /** Automatic = 0 */
  Automatic: 0,
  /** Status = 10 */
  Status: 10,
  /** HealthOrganizationRelativeName = 15 */
  HealthOrganizationRelativeName: 15,
  /** SchoolOrganizationRelativeName = 20 */
  SchoolOrganizationRelativeName: 20,
  /** AgreementName = 25 */
  AgreementName: 25,
  /** StartDate = 30 */
  StartDate: 30,
  /** EndDate = 35 */
  EndDate: 35,
}
/** See source definition here: CN.Infrastructure\Features\Agreements\AgreementStatus.cs */
export const AgreementStatus = {
  /** PendingClinicalSite = 0 */
  PendingClinicalSite: 0,
  /** PendingSchool = 5 */
  PendingSchool: 5,
  /** Active = 30 */
  Active: 30,
  /** Expired = 50 */
  Expired: 50,
  /** Closed = 60 */
  Closed: 60,
  /** Unsubmitted = -1 */
  Unsubmitted: -1,
}
/** See source definition here: CN.Infrastructure\Models\Enums\ArchiveActiveFilter.cs */
export const ArchiveActiveFilter = {
  /** Active = 10 */
  Active: 10,
  /** Archived = 20 */
  Archived: 20,
  /** All = 30 */
  All: 30,
}
/** See source definition here: CN.Infrastructure\Features\Capacities\AutoReleaseUnscheduledMatchesWhen.cs */
export const AutoReleaseUnscheduledMatchesWhen = {
  /** Never = 0 */
  Never: 0,
  /** Confirmed = 10 */
  Confirmed: 10,
  /** PastConfirmDesiredMatchCountByDate = 20 */
  PastConfirmDesiredMatchCountByDate: 20,
  /** PastSchedulingEndDate = 30 */
  PastSchedulingEndDate: 30,
}
/** See source definition here: CN.Infrastructure\Models\Enums\BulkAction.cs */
export const BulkAction = {
  /** Email = 10 */
  Email: 10,
  /** Comment = 20 */
  Comment: 20,
  /** Pause = 30 */
  Pause: 30,
  /** Close = 40 */
  Close: 40,
  /** ExtendOnboardingDeadline = 50 */
  ExtendOnboardingDeadline: 50,
  /** ManageCustomTags = 60 */
  ManageCustomTags: 60,
  /** ChangeDates = 70 */
  ChangeDates: 70,
  /** ChangeCourse = 80 */
  ChangeCourse: 80,
  /** ChangeDueDates = 90 */
  ChangeDueDates: 90,
  /** Reopen = 100 */
  Reopen: 100,
}
/** See source definition here: CN.Infrastructure\Features\Cache\CacheTaskType.cs */
export const CacheTaskType = {
  /** UpdateMatchSteps = 1 */
  UpdateMatchSteps: 1,
  /** UpdateMatchHasProposedChanges = 2 */
  UpdateMatchHasProposedChanges: 2,
  /** UpdateMatchKeywords = 3 */
  UpdateMatchKeywords: 3,
  /** SyncAutomaticMatchUsersForMatchIds = 4 */
  SyncAutomaticMatchUsersForMatchIds: 4,
  /** SyncAutomaticMatchUsersForStudentUserIds = 6 */
  SyncAutomaticMatchUsersForStudentUserIds: 6,
  /** SyncAutomaticMatchUsersForCapacityIds = 7 */
  SyncAutomaticMatchUsersForCapacityIds: 7,
  /** SyncAutomaticMatchUsersForServiceIds = 8 */
  SyncAutomaticMatchUsersForServiceIds: 8,
  /** SyncAutomaticMatchUsersForOrgStaffIds = 9 */
  SyncAutomaticMatchUsersForOrgStaffIds: 9,
  /** UpdateMatchStatus = 10 */
  UpdateMatchStatus: 10,
  /** InsertSchoolComplianceMatchUserStepsForStepId = 11 */
  InsertSchoolComplianceMatchUserStepsForStepId: 11,
  /** MergeSchoolComplianceMatchUserStepsForStepId = 12 */
  MergeSchoolComplianceMatchUserStepsForStepId: 12,
  /** DeleteSchoolComplianceMatchUserStepsForStepId = 13 */
  DeleteSchoolComplianceMatchUserStepsForStepId: 13,
  /** MergeSchoolComplianceMatchUserStepsForOrgStaffId = 14 */
  MergeSchoolComplianceMatchUserStepsForOrgStaffId: 14,
  /** DeleteSchoolComplianceMatchUserStepsForOrgStaffId = 15 */
  DeleteSchoolComplianceMatchUserStepsForOrgStaffId: 15,
  /** InsertSchoolComplianceMatchUserStepsForStudentUserIdAtSchoolOrgId = 16 */
  InsertSchoolComplianceMatchUserStepsForStudentUserIdAtSchoolOrgId: 16,
  /** MergeSchoolComplianceMatchUserStepsForStudentUserIdAtSchoolOrgId = 17 */
  MergeSchoolComplianceMatchUserStepsForStudentUserIdAtSchoolOrgId: 17,
  /** DeleteSchoolComplianceMatchUserStepsForStudentUserIdAtSchoolOrgId = 18 */
  DeleteSchoolComplianceMatchUserStepsForStudentUserIdAtSchoolOrgId: 18,
  /** MarkNotificationsAsReadForUserId = 19 */
  MarkNotificationsAsReadForUserId: 19,
}
/** See source definition here: CN.Infrastructure\Features\Capacities\ListCapacityRequest.cs */
export const CapacityListProperty = {
  /** Automatic = 0 */
  Automatic: 0,
  /** OrganizationRelativeName = 10 */
  OrganizationRelativeName: 10,
  /** Name = 15 */
  Name: 15,
  /** ServiceName = 20 */
  ServiceName: 20,
  /** StartDate = 30 */
  StartDate: 30,
  /** EndDate = 35 */
  EndDate: 35,
  /** OpenForScheduling = 40 */
  OpenForScheduling: 40,
  /** PostToSearchPage = 45 */
  PostToSearchPage: 45,
  /** AllowCoordinatorScheduling = 50 */
  AllowCoordinatorScheduling: 50,
}
/** See source definition here: CN.Infrastructure\Models\Enums\ChangeSource.cs */
export const ChangeSource = {
  /** Provider = 0 */
  Provider: 0,
  /** StudentOrSchool = 1 */
  StudentOrSchool: 1,
}
/** See source definition here: CN.Infrastructure\Models\Enums\CloseReason.cs */
export const CloseReason = {
  /** NoSchoolAgreement = 10 */
  NoSchoolAgreement: 10,
  /** RequirementsNotMet = 20 */
  RequirementsNotMet: 20,
  /** DatesDoNotWork = 30 */
  DatesDoNotWork: 30,
  /** OnboardingNotCompletedInTime = 40 */
  OnboardingNotCompletedInTime: 40,
  /** ApplicationVerificationNotCompletedInTime = 41 */
  ApplicationVerificationNotCompletedInTime: 41,
  /** ClinicalSiteDidntAcceptInTime = 42 */
  ClinicalSiteDidntAcceptInTime: 42,
  /** StudentOrSchoolDidntAcceptInTime = 43 */
  StudentOrSchoolDidntAcceptInTime: 43,
  /** ClinicalSiteDidntAcceptFromWaitlistInTime = 44 */
  ClinicalSiteDidntAcceptFromWaitlistInTime: 44,
  /** Other = 100 */
  Other: 100,
  /** NotEligible = 120 */
  NotEligible: 120,
}
addDisplays(CloseReason, 42, "Clinical site didn't accept in time", 43, "Student or school didn't accept in time", 44, "Clinical site didn't accept from waitlist in time")
/** See source definition here: CN.Infrastructure\Models\Enums\CommentActivityFilter.cs */
export const CommentActivityFilter = {
  /** Comment = 10 */
  Comment: 10,
  /** Activity = 20 */
  Activity: 20,
  /** All = 30 */
  All: 30,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\ComparisonOperator.cs */
export const ComparisonOperator = {
  /** Is = 5 */
  Is: 5,
  /** IsNot = 10 */
  IsNot: 10,
  /** Contains = 15 */
  Contains: 15,
  /** DoesNotContain = 20 */
  DoesNotContain: 20,
  /** StartsWith = 25 */
  StartsWith: 25,
  /** DoesNotStartWith = 30 */
  DoesNotStartWith: 30,
  /** EndsWith = 35 */
  EndsWith: 35,
  /** DoesNotEndWith = 40 */
  DoesNotEndWith: 40,
  /** IsEmpty = 45 */
  IsEmpty: 45,
  /** IsNotEmpty = 50 */
  IsNotEmpty: 50,
  /** IsNull = 55 */
  IsNull: 55,
  /** IsNotNull = 60 */
  IsNotNull: 60,
  /** IsEmptyOrNull = 65 */
  IsEmptyOrNull: 65,
  /** IsNotEmptyNorNull = 70 */
  IsNotEmptyNorNull: 70,
}
/** See source definition here: CN.Infrastructure\Models\Enums\ComparisonType.cs */
export const ComparisonType = {
  /** Is = 0 */
  Is: 0,
  /** IsNot = 1 */
  IsNot: 1,
  /** Contains = 2 */
  Contains: 2,
  /** DoesNotContain = 3 */
  DoesNotContain: 3,
  /** GreaterThan = 4 */
  GreaterThan: 4,
  /** LessThan = 5 */
  LessThan: 5,
  /** IsBefore = 6 */
  IsBefore: 6,
  /** IsAfter = 7 */
  IsAfter: 7,
  /** IsYes = 8 */
  IsYes: 8,
  /** IsNo = 9 */
  IsNo: 9,
  /** IsChecked = 10 */
  IsChecked: 10,
  /** IsNotChecked = 11 */
  IsNotChecked: 11,
  /** IsNotEmpty = 12 */
  IsNotEmpty: 12,
  /** IsEmpty = 13 */
  IsEmpty: 13,
  /** WasEver = 14 */
  WasEver: 14,
  /** WasNever = 15 */
  WasNever: 15,
  /** ContainsAny = 16 */
  ContainsAny: 16,
  /** DoesNotContainAny = 17 */
  DoesNotContainAny: 17,
  /** IsIncludedIn = 18 */
  IsIncludedIn: 18,
  /** IsNotIncludedIn = 19 */
  IsNotIncludedIn: 19,
}
/** See source definition here: CN.Infrastructure\Models\Enums\CountBy.cs */
export const CountBy = {
  /** ApplicationsForRotations = 0 */
  ApplicationsForRotations: 0,
  /** AcceptedRotations = 1 */
  AcceptedRotations: 1,
  /** ClosedRotations = 2 */
  ClosedRotations: 2,
  /** StudentsWhoAppliedForRotations = 3 */
  StudentsWhoAppliedForRotations: 3,
  /** StudentsWithAcceptedRotations = 4 */
  StudentsWithAcceptedRotations: 4,
  /** StudentsWithClosedRotations = 5 */
  StudentsWithClosedRotations: 5,
  /** StudentHours = 6 */
  StudentHours: 6,
  /** StudentsWithActiveRotations = 7 */
  StudentsWithActiveRotations: 7,
  /** ActiveRotations = 8 */
  ActiveRotations: 8,
  /** StudentHourLogs = 9 */
  StudentHourLogs: 9,
}
/** See source definition here: CN.Infrastructure\Features\Courses\ListCoursesRequest.cs */
export const CourseListProperty = {
  /** Automatic = 0 */
  Automatic: 0,
  /** Name = 10 */
  Name: 10,
  /** Description = 20 */
  Description: 20,
  /** StartDate = 30 */
  StartDate: 30,
  /** EndDate = 40 */
  EndDate: 40,
  /** OrgName = 50 */
  OrgName: 50,
  /** TeamName = 60 */
  TeamName: 60,
}
/** See source definition here: CN.Infrastructure\Features\Courses\CourseStatus.cs */
export const CourseStatus = {
  /** Upcoming = 10 */
  Upcoming: 10,
  /** Active = 20 */
  Active: 20,
  /** Completed = 30 */
  Completed: 30,
  /** Incomplete = 40 */
  Incomplete: 40,
}
/** See source definition here: CN.Infrastructure\Models\Enums\CriteriaFilterType.cs */
export const CriteriaFilterType = {
  /** AgreementFilter = 0 */
  AgreementFilter: 0,
  /** CapacityFilter = 1 */
  CapacityFilter: 1,
  /** CourseFilter = 2 */
  CourseFilter: 2,
  /** MatchFilter = 3 */
  MatchFilter: 3,
  /** ServiceFilter = 4 */
  ServiceFilter: 4,
  /** StepSubmissionFilter = 5 */
  StepSubmissionFilter: 5,
  /** StudentFilter = 6 */
  StudentFilter: 6,
  /** UserDocumentFilter = 7 */
  UserDocumentFilter: 7,
}
/** See source definition here: CN.Infrastructure\Models\Enums\DashboardViewCategory.cs */
export const DashboardViewCategory = {
  /** Views = 0 */
  Views: 0,
  /** Reports = 10 */
  Reports: 10,
  /** MySteps = 20 */
  MySteps: 20,
}
/** See source definition here: CN.Infrastructure\Models\Enums\DateCountType.cs */
export const DateCountType = {
  /** ScheduledBetween = 0 */
  ScheduledBetween: 0,
  /** ScheduledToStartBetween = 1 */
  ScheduledToStartBetween: 1,
  /** ScheduledToEndBetween = 2 */
  ScheduledToEndBetween: 2,
  /** CreatedBetween = 3 */
  CreatedBetween: 3,
  /** ClosedBetween = 4 */
  ClosedBetween: 4,
  /** HoursBetween = 5 */
  HoursBetween: 5,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\Dates\DateTimeComparison.cs */
export const DateTimeComparison = {
  /** IsBefore = 10 */
  IsBefore: 10,
  /** IsBeforeOrEqual = 15 */
  IsBeforeOrEqual: 15,
  /** Is = 20 */
  Is: 20,
  /** IsAfter = 30 */
  IsAfter: 30,
  /** IsAfterOrEqual = 35 */
  IsAfterOrEqual: 35,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\Dates\DateTimeComparisonProviderType.cs */
export const DateTimeComparisonProviderType = {
  /** Parameterless = 5 */
  Parameterless: 5,
  /** ExactDate = 10 */
  ExactDate: 10,
  /** RelativeDays = 20 */
  RelativeDays: 20,
  /** RelativeWeeks = 30 */
  RelativeWeeks: 30,
  /** RelativeMonths = 40 */
  RelativeMonths: 40,
  /** RelativeQuarters = 50 */
  RelativeQuarters: 50,
  /** RelativeYears = 60 */
  RelativeYears: 60,
}
/** See source definition here: CN.Infrastructure\Models\Enums\EducationType.cs */
export const EducationType = {
  /** College = 0 */
  College: 0,
  /** GraduateSchool = 1 */
  GraduateSchool: 1,
  /** JuniorCollege = 2 */
  JuniorCollege: 2,
  /** HighSchool = 3 */
  HighSchool: 3,
}
/** See source definition here: CN.Infrastructure\Models\Enums\EndorsementStatus.cs */
export const EndorsementStatus = {
  /** Pending = 0 */
  Pending: 0,
  /** Approved = 1 */
  Approved: 1,
  /** Rejected = 2 */
  Rejected: 2,
}
/** See source definition here: CN.Infrastructure\Models\Enums\EvaluationReportGroupBy.cs */
export const EvaluationReportGroupBy = {
  /** StepSubmission = 0 */
  StepSubmission: 0,
  /** Step = 1 */
  Step: 1,
  /** ClinicalSite = 2 */
  ClinicalSite: 2,
  /** School = 3 */
  School: 3,
  /** Service = 4 */
  Service: 4,
  /** PersonWhoWasEvaluated = 5 */
  PersonWhoWasEvaluated: 5,
  /** Discipline = 6 */
  Discipline: 6,
  /** StudentYear = 7 */
  StudentYear: 7,
  /** RequestType = 8 */
  RequestType: 8,
}
/** See source definition here: CN.Infrastructure\Models\Enums\FeatureType.cs */
export const FeatureType = {
  /** HealthInstitution = 1 */
  HealthInstitution: 1,
  /** TeachingInstitution = 2 */
  TeachingInstitution: 2,
  /** Consortium = 3 */
  Consortium: 3,
  /** CustomFieldsOrg = 30 */
  CustomFieldsOrg: 30,
  /** CustomFieldsStaff = 31 */
  CustomFieldsStaff: 31,
  /** CustomFieldsStudent = 32 */
  CustomFieldsStudent: 32,
  /** CustomTags = 40 */
  CustomTags: 40,
  /** CoreScheduling = 110 */
  CoreScheduling: 110,
  /** ElectiveScheduling = 120 */
  ElectiveScheduling: 120,
  /** CapacityCalendar = 150 */
  CapacityCalendar: 150,
  /** AgreementManagement = 220 */
  AgreementManagement: 220,
  /** HourLogging = 300 */
  HourLogging: 300,
  /** Evaluations = 320 */
  Evaluations: 320,
  /** StudentRequirements = 410 */
  StudentRequirements: 410,
  /** SchoolComplianceRequirements = 415 */
  SchoolComplianceRequirements: 415,
  /** FacultyRequirements = 430 */
  FacultyRequirements: 430,
  /** ReportRotationHistory = 500 */
  ReportRotationHistory: 500,
  /** ReportRotationBreakdown = 510 */
  ReportRotationBreakdown: 510,
  /** ReportStudentFacultyData = 530 */
  ReportStudentFacultyData: 530,
  /** LocationProfiles = 610 */
  LocationProfiles: 610,
  /** TeamManagement = 620 */
  TeamManagement: 620,
  /** BulkEmail = 630 */
  BulkEmail: 630,
  /** BulkComment = 631 */
  BulkComment: 631,
  /** BulkPause = 632 */
  BulkPause: 632,
  /** BulkClose = 633 */
  BulkClose: 633,
  /** BulkExtendOnboardingDeadline = 634 */
  BulkExtendOnboardingDeadline: 634,
  /** BulkManageCustomTags = 635 */
  BulkManageCustomTags: 635,
  /** BulkChangeDates = 636 */
  BulkChangeDates: 636,
  /** BulkChangeCourse = 637 */
  BulkChangeCourse: 637,
  /** BulkReopen = 638 */
  BulkReopen: 638,
  /** CustomizableITSecurityControls = 740 */
  CustomizableITSecurityControls: 740,
  /** CustomIntegrations = 830 */
  CustomIntegrations: 830,
  /** Courses = 840 */
  Courses: 840,
  /** SingleSignOn = 850 */
  SingleSignOn: 850,
  /** MySteps = 860 */
  MySteps: 860,
}
addDisplays(FeatureType, 740, 'Customizable IT security controls')
/** See source definition here: CN.Infrastructure\DynamicQuery\FilterMode.cs */
export const FilterMode = {
  /** Simple = 1 */
  Simple: 1,
  /** Advanced = 2 */
  Advanced: 2,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\FilterType.cs */
export const FilterType = {
  /** Match = 5 */
  Match: 5,
  /** Agreements = 10 */
  Agreements: 10,
  /** MatchCapacity = 20 */
  MatchCapacity: 20,
  /** MatchShift = 25 */
  MatchShift: 25,
  /** MatchRequestType = 30 */
  MatchRequestType: 30,
  /** MatchHostOrgCoordinators = 40 */
  MatchHostOrgCoordinators: 40,
  /** MatchCloseReason = 50 */
  MatchCloseReason: 50,
  /** MatchEndDate = 60 */
  MatchEndDate: 60,
  /** MatchHasProposedChanges = 70 */
  MatchHasProposedChanges: 70,
  /** Steps = 80 */
  Steps: 80,
  /** StepTypes = 82 */
  StepTypes: 82,
  /** MatchHasIncompleteSteps = 85 */
  MatchHasIncompleteSteps: 85,
  /** MatchHasStepsICanComplete = 90 */
  MatchHasStepsICanComplete: 90,
  /** MatchHasStepsDueSoon = 95 */
  MatchHasStepsDueSoon: 95,
  /** MatchHasStepsICanVerify = 100 */
  MatchHasStepsICanVerify: 100,
  /** KeywordSearch = 110 */
  KeywordSearch: 110,
  /** HostOrgs = 115 */
  HostOrgs: 115,
  /** MatchHostOrgAddress = 116 */
  MatchHostOrgAddress: 116,
  /** Teams = 120 */
  Teams: 120,
  /** MatchPastStatuses = 130 */
  MatchPastStatuses: 130,
  /** MatchPreceptors = 140 */
  MatchPreceptors: 140,
  /** MatchHasPlaceholderStudents = 145 */
  MatchHasPlaceholderStudents: 145,
  /** GuestOrgCoordinators = 150 */
  GuestOrgCoordinators: 150,
  /** SchoolFaculty = 160 */
  SchoolFaculty: 160,
  /** GuestOrgs = 170 */
  GuestOrgs: 170,
  /** CapacityGuestHasRotations = 172 */
  CapacityGuestHasRotations: 172,
  /** CapacityGuestRotationRequestRestrictionStartDate = 173 */
  CapacityGuestRotationRequestRestrictionStartDate: 173,
  /** CapacityGuestRotationRequestRestrictionEndDate = 174 */
  CapacityGuestRotationRequestRestrictionEndDate: 174,
  /** MatchGuestOrgAddress = 175 */
  MatchGuestOrgAddress: 175,
  /** Services = 180 */
  Services: 180,
  /** MatchStartDate = 190 */
  MatchStartDate: 190,
  /** MatchCreatedDate = 192 */
  MatchCreatedDate: 192,
  /** MatchSubmittedDate = 195 */
  MatchSubmittedDate: 195,
  /** MatchClosedDate = 196 */
  MatchClosedDate: 196,
  /** MatchStatusCategory = 200 */
  MatchStatusCategory: 200,
  /** MatchStatuses = 210 */
  MatchStatuses: 210,
  /** Disciplines = 220 */
  Disciplines: 220,
  /** Students = 230 */
  Students: 230,
  /** StudentYears = 240 */
  StudentYears: 240,
  /** StudentGraduationDate = 242 */
  StudentGraduationDate: 242,
  /** MatchWaitlistedBy = 250 */
  MatchWaitlistedBy: 250,
  /** StepSubmissionTargetUser = 260 */
  StepSubmissionTargetUser: 260,
  /** StepSubmissionUser = 261 */
  StepSubmissionUser: 261,
  /** MatchCustomTags = 270 */
  MatchCustomTags: 270,
  /** StudentCustomTags = 271 */
  StudentCustomTags: 271,
  /** CapacityLocations = 280 */
  CapacityLocations: 280,
  /** RotationTypes = 290 */
  RotationTypes: 290,
  /** Specialties = 300 */
  Specialties: 300,
  /** CanManage = 310 */
  CanManage: 310,
  /** CapacityOpenForScheduling = 320 */
  CapacityOpenForScheduling: 320,
  /** CapacityPostToSearchPage = 330 */
  CapacityPostToSearchPage: 330,
  /** CapacityAvailableOnSearchPage = 332 */
  CapacityAvailableOnSearchPage: 332,
  /** CapacityAllowCoordinatorScheduling = 340 */
  CapacityAllowCoordinatorScheduling: 340,
  /** CapacityAllowPlaceholderStudents = 345 */
  CapacityAllowPlaceholderStudents: 345,
  /** CapacityAllowGroups = 346 */
  CapacityAllowGroups: 346,
  /** CapacityStartDate = 350 */
  CapacityStartDate: 350,
  /** CapacityEndDate = 360 */
  CapacityEndDate: 360,
  /** GuestSchedulingStartDate = 369 */
  GuestSchedulingStartDate: 369,
  /** GuestSchedulingEndDate = 370 */
  GuestSchedulingEndDate: 370,
  /** CapacityMatchConfirmation = 380 */
  CapacityMatchConfirmation: 380,
  /** CapacityMatchCounts = 390 */
  CapacityMatchCounts: 390,
  /** CanSwitchMatchToCapacity = 392 */
  CanSwitchMatchToCapacity: 392,
  /** CapacityStudentCount = 395 */
  CapacityStudentCount: 395,
  /** AgreementStatuses = 400 */
  AgreementStatuses: 400,
  /** AgreementPastStatuses = 410 */
  AgreementPastStatuses: 410,
  /** ExcludeInherited = 420 */
  ExcludeInherited: 420,
  /** OpenGuestWithMatchesIn = 425 */
  OpenGuestWithMatchesIn: 425,
  /** ConnectedHostOrg = 430 */
  ConnectedHostOrg: 430,
  /** ConnectedGuestOrg = 440 */
  ConnectedGuestOrg: 440,
  /** Course = 445 */
  Course: 445,
  /** ArchiveActiveAll = 450 */
  ArchiveActiveAll: 450,
  /** ImportStaffStatus = 455 */
  ImportStaffStatus: 455,
  /** Pending = 460 */
  Pending: 460,
  /** Email = 470 */
  Email: 470,
  /** ExcludeUsers = 480 */
  ExcludeUsers: 480,
  /** CourseStartDate = 490 */
  CourseStartDate: 490,
  /** CourseEndDate = 500 */
  CourseEndDate: 500,
  /** LastModified = 501 */
  LastModified: 501,
  /** DateModified = 530 */
  DateModified: 530,
  /** CompleteFromDate = 532 */
  CompleteFromDate: 532,
  /** DueDate = 535 */
  DueDate: 535,
  /** StepSubmissionStatus = 538 */
  StepSubmissionStatus: 538,
  /** IsCompleted = 540 */
  IsCompleted: 540,
  /** IsVerified = 545 */
  IsVerified: 545,
  /** NameSuggestionStatuses = 550 */
  NameSuggestionStatuses: 550,
  /** IsRejected = 555 */
  IsRejected: 555,
  /** IsWaived = 557 */
  IsWaived: 557,
  /** HasIssues = 560 */
  HasIssues: 560,
  /** PossibleDuplicateOrg = 562 */
  PossibleDuplicateOrg: 562,
  /** AwaitingProposalVerification = 563 */
  AwaitingProposalVerification: 563,
  /** Verification = 564 */
  Verification: 564,
  /** VerificationExpirationDate = 565 */
  VerificationExpirationDate: 565,
  /** NameSuggestionFieldComparison = 570 */
  NameSuggestionFieldComparison: 570,
  /** IsSkipped = 575 */
  IsSkipped: 575,
  /** ICanComplete = 580 */
  ICanComplete: 580,
  /** ICanVerify = 585 */
  ICanVerify: 585,
  /** OwnerOrgs = 590 */
  OwnerOrgs: 590,
  /** OwnerUsers = 640 */
  OwnerUsers: 640,
  /** Address = 650 */
  Address: 650,
  /** FeatureTypes = 660 */
  FeatureTypes: 660,
  /** MatchCount = 670 */
  MatchCount: 670,
  /** AgreementCount = 671 */
  AgreementCount: 671,
  /** CapacityCount = 672 */
  CapacityCount: 672,
  /** ExcludeOrgs = 680 */
  ExcludeOrgs: 680,
  /** ExcludeParentOrgs = 681 */
  ExcludeParentOrgs: 681,
  /** HasAncestorOrg = 682 */
  HasAncestorOrg: 682,
  /** HasChildOrg = 683 */
  HasChildOrg: 683,
  /** HasDescendantOrg = 684 */
  HasDescendantOrg: 684,
  /** HasParentOrg = 685 */
  HasParentOrg: 685,
  /** HasRelativeOrg = 686 */
  HasRelativeOrg: 686,
  /** OrgTags = 690 */
  OrgTags: 690,
  /** ExcludeStaff = 700 */
  ExcludeStaff: 700,
  /** StaffRole = 710 */
  StaffRole: 710,
  /** StaffRoleOrg = 715 */
  StaffRoleOrg: 715,
  /** HasAccess = 720 */
  HasAccess: 720,
  /** TargetOrgs = 730 */
  TargetOrgs: 730,
  /** UserName = 740 */
  UserName: 740,
  /** ExcludeCx = 750 */
  ExcludeCx: 750,
  /** CapacityShiftLocations = 760 */
  CapacityShiftLocations: 760,
}
/** See source definition here: CN.Infrastructure\Models\Enums\GroupBy.cs */
export const GroupBy = {
  /** Shift = 5 */
  Shift: 5,
  /** Opportunity = 10 */
  Opportunity: 10,
  /** RequestType = 15 */
  RequestType: 15,
  /** Agreement = 20 */
  Agreement: 20,
  /** Service = 25 */
  Service: 25,
  /** ClinicalSite = 30 */
  ClinicalSite: 30,
  /** ClinicalTeam = 33 */
  ClinicalTeam: 33,
  /** School = 35 */
  School: 35,
  /** GuestTeam = 36 */
  GuestTeam: 36,
  /** Student = 37 */
  Student: 37,
  /** Coordinator = 40 */
  Coordinator: 40,
  /** SchoolCoordinator = 45 */
  SchoolCoordinator: 45,
  /** Discipline = 50 */
  Discipline: 50,
  /** SchoolFaculty = 55 */
  SchoolFaculty: 55,
  /** Preceptor = 60 */
  Preceptor: 60,
}
/** See source definition here: CN.Infrastructure\Models\Enums\HourType.cs */
export const HourType = {
  /** OnSite = 3 */
  OnSite: 3,
  /** CalledInFromHome = 9 */
  CalledInFromHome: 9,
  /** Didactic = 12 */
  Didactic: 12,
}
/** See source definition here: CN.Infrastructure\Features\ImportFiles\ListImportFilesRequest.cs */
export const ImportFileListProperty = {
  /** Automatic = 0 */
  Automatic: 0,
  /** UploadedByUser = 10 */
  UploadedByUser: 10,
  /** DateTimeUploaded = 20 */
  DateTimeUploaded: 20,
  /** PersonaOrgName = 30 */
  PersonaOrgName: 30,
  /** NameOriginal = 40 */
  NameOriginal: 40,
  /** Size = 50 */
  Size: 50,
}
/** See source definition here: CN.Infrastructure\Features\ImportStaff\ListImportStaffRequest.cs */
export const ImportStaffListProperty = {
  /** RowNumber = 0 */
  RowNumber: 0,
  /** LegalFirstName = 10 */
  LegalFirstName: 10,
  /** LegalLastName = 20 */
  LegalLastName: 20,
  /** Email = 30 */
  Email: 30,
  /** Title = 40 */
  Title: 40,
  /** ExternalId = 50 */
  ExternalId: 50,
  /** StaffOrg = 60 */
  StaffOrg: 60,
}
/** See source definition here: CN.Infrastructure\Models\Enums\ImportStatusFilter.cs */
export const ImportStatusFilter = {
  /** Pending = 10 */
  Pending: 10,
  /** Imported = 20 */
  Imported: 20,
  /** Deleted = 30 */
  Deleted: 30,
  /** All = 40 */
  All: 40,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\Capacity\Filters\CapacityMatchConfirmationFilter.cs */
export const MatchConfirmationOption = {
  /** HasUnconfirmedMatches = 1 */
  HasUnconfirmedMatches: 1,
  /** HasConfirmedMatches = 2 */
  HasConfirmedMatches: 2,
}
/** See source definition here: CN.Infrastructure\Features\Capacities\MatchHasBeen.cs */
export const MatchHasBeen = {
  /** Submitted = 10 */
  Submitted: 10,
  /** Accepted = 20 */
  Accepted: 20,
}
/** See source definition here: CN.Infrastructure\Models\Enums\MatchListSortProperty.cs */
export const MatchListSortProperty = {
  /** Status = 0 */
  Status: 0,
  /** DateCreated = 10 */
  DateCreated: 10,
  /** DateSubmitted = 15 */
  DateSubmitted: 15,
  /** StartDate = 20 */
  StartDate: 20,
  /** ServiceName = 25 */
  ServiceName: 25,
  /** CapacityName = 30 */
  CapacityName: 30,
  /** OrgName = 35 */
  OrgName: 35,
  /** CourseName = 40 */
  CourseName: 40,
}
/** See source definition here: CN.Infrastructure\Models\Enums\MatchPreferenceType.cs */
export const MatchPreferenceType = {
  /** Allowlisted = 1 */
  Allowlisted: 1,
  /** Blocklisted = 2 */
  Blocklisted: 2,
}
/** See source definition here: CN.Infrastructure\Models\Enums\MatchRequestType.cs */
export const MatchRequestType = {
  /** StudentRequested = 0 */
  StudentRequested: 0,
  /** HostCoordinatorScheduled = 1 */
  HostCoordinatorScheduled: 1,
  /** GuestCoordinatorScheduled = 2 */
  GuestCoordinatorScheduled: 2,
}
addDisplays(MatchRequestType, 0, 'Requested by a student', 1, 'Scheduled by a clinical site coordinator', 2, 'Scheduled by a school coordinator')
/** See source definition here: CN.Infrastructure\Models\Enums\MatchRole.cs */
export const MatchRole = {
  /** ClinicCoordinator = 1 */
  ClinicCoordinator: 1,
  /** Student = 2 */
  Student: 2,
  /** SchoolCoordinator = 3 */
  SchoolCoordinator: 3,
  /** SchoolFaculty = 4 */
  SchoolFaculty: 4,
  /** Preceptor = 5 */
  Preceptor: 5,
  /** HostAdmin = 6 */
  HostAdmin: 6,
  /** HostViewer = 7 */
  HostViewer: 7,
  /** GuestAdmin = 8 */
  GuestAdmin: 8,
  /** GuestViewer = 9 */
  GuestViewer: 9,
}
addDisplays(MatchRole, 6, 'Clinic admin', 7, 'Clinic viewer', 8, 'School admin', 9, 'School viewer')
/** See source definition here: CN.Infrastructure\Models\Enums\MatchStatus.cs */
export const MatchStatus = {
  /** PendingApplicationVerification = 5 */
  PendingApplicationVerification: 5,
  /** PendingClinicalSite = 8 */
  PendingClinicalSite: 8,
  /** Waitlisted = 9 */
  Waitlisted: 9,
  /** Onboarding = 10 */
  Onboarding: 10,
  /** Ready = 20 */
  Ready: 20,
  /** Paused = 25 */
  Paused: 25,
  /** Active = 30 */
  Active: 30,
  /** Offboarding = 35 */
  Offboarding: 35,
  /** Completed = 50 */
  Completed: 50,
  /** Closed = 60 */
  Closed: 60,
  /** Unsubmitted = -1 */
  Unsubmitted: -1,
}
/** See source definition here: CN.Infrastructure\Features\NameSuggestions\NameSuggestionField.cs */
export const NameSuggestionField = {
  /** AiNamePrefix = 10 */
  AiNamePrefix: 10,
  /** AiFirstName = 20 */
  AiFirstName: 20,
  /** AiPreferredName = 30 */
  AiPreferredName: 30,
  /** AiMiddleName = 40 */
  AiMiddleName: 40,
  /** AiLastName = 50 */
  AiLastName: 50,
  /** AiMaidenName = 60 */
  AiMaidenName: 60,
  /** AiNameSuffix = 70 */
  AiNameSuffix: 70,
  /** AiCredentials = 80 */
  AiCredentials: 80,
  /** CnNamePrefix = 90 */
  CnNamePrefix: 90,
  /** CnFirstName = 100 */
  CnFirstName: 100,
  /** CnPreferredName = 110 */
  CnPreferredName: 110,
  /** CnMiddleName = 120 */
  CnMiddleName: 120,
  /** CnLastName = 130 */
  CnLastName: 130,
  /** CnNameSuffix = 140 */
  CnNameSuffix: 140,
  /** CnCredentials = 150 */
  CnCredentials: 150,
  /** UserNamePrefix = 160 */
  UserNamePrefix: 160,
  /** UserFirstName = 170 */
  UserFirstName: 170,
  /** UserPreferredName = 180 */
  UserPreferredName: 180,
  /** UserMiddleName = 190 */
  UserMiddleName: 190,
  /** UserLastName = 200 */
  UserLastName: 200,
  /** UserNameSuffix = 210 */
  UserNameSuffix: 210,
  /** UserCredentials = 220 */
  UserCredentials: 220,
}
/** See source definition here: CN.Infrastructure\Features\NameSuggestions\NameSuggestionIssue.cs */
export const NameSuggestionIssue = {
  /** ExceptionThrown = 10 */
  ExceptionThrown: 10,
  /** AiResponseIsNotValidJson = 20 */
  AiResponseIsNotValidJson: 20,
  /** AiResponseHasKeysWithWrongTypes = 30 */
  AiResponseHasKeysWithWrongTypes: 30,
  /** AiResponseHasUnrecognizedKeys = 40 */
  AiResponseHasUnrecognizedKeys: 40,
  /** AiResponseConfidenceOutOfRange = 50 */
  AiResponseConfidenceOutOfRange: 50,
}
/** See source definition here: CN.Infrastructure\Features\NameSuggestions\ListNameSuggestionsRequest.cs */
export const NameSuggestionListProperty = {
  /** Default = 0 */
  Default: 0,
  /** AiResponseConfidence = 10 */
  AiResponseConfidence: 10,
  /** DeltaLength = 20 */
  DeltaLength: 20,
  /** NameLegacy = 30 */
  NameLegacy: 30,
}
/** See source definition here: CN.Infrastructure\Features\NameSuggestions\NameSuggestionStatus.cs */
export const NameSuggestionStatus = {
  /** AiSuggestionless = 10 */
  AiSuggestionless: 10,
  /** AiSuggested = 20 */
  AiSuggested: 20,
  /** CnSuggested = 30 */
  CnSuggested: 30,
  /** UserSubmitted = 40 */
  UserSubmitted: 40,
}
/** See source definition here: CN.Infrastructure\Features\Notifications\NotificationType.cs */
export const NotificationType = {
  /** MatchRequested = 0 */
  MatchRequested: 0,
  /** MatchStudentAccepted = 1 */
  MatchStudentAccepted: 1,
  /** MatchStudentClosed = 2 */
  MatchStudentClosed: 2,
  /** MatchStudentProposedChanges = 3 */
  MatchStudentProposedChanges: 3,
  /** MatchClinicalSiteAccepted = 4 */
  MatchClinicalSiteAccepted: 4,
  /** MatchClinicalSiteClosed = 5 */
  MatchClinicalSiteClosed: 5,
  /** MatchClinicalSiteMadeChanges = 6 */
  MatchClinicalSiteMadeChanges: 6,
  /** EndorsementReceived = 7 */
  EndorsementReceived: 7,
  /** ConnectionRequested = 8 */
  ConnectionRequested: 8,
  /** ConnectionAccepted = 9 */
  ConnectionAccepted: 9,
  /** StaffConfirmed = 10 */
  StaffConfirmed: 10,
  /** StaffRemoved = 11 */
  StaffRemoved: 11,
  /** StaffRolesChanged = 12 */
  StaffRolesChanged: 12,
  /** StudentConfirmed = 13 */
  StudentConfirmed: 13,
  /** StudentRejected = 14 */
  StudentRejected: 14,
  /** MatchAddedBySchoolCoordinator = 15 */
  MatchAddedBySchoolCoordinator: 15,
  /** MatchCommented = 16 */
  MatchCommented: 16,
  /** MatchOnboardingChanged = 17 */
  MatchOnboardingChanged: 17,
  /** NewMessageReceived = 18 */
  NewMessageReceived: 18,
  /** StaffAdded = 19 */
  StaffAdded: 19,
  /** MatchCommentEdited = 20 */
  MatchCommentEdited: 20,
  /** MatchAcceptedProposedChanges = 21 */
  MatchAcceptedProposedChanges: 21,
  /** MatchRejectedProposedChanges = 22 */
  MatchRejectedProposedChanges: 22,
  /** MatchClinicalSiteProposedChanges = 23 */
  MatchClinicalSiteProposedChanges: 23,
  /** MatchStudentMadeChanges = 24 */
  MatchStudentMadeChanges: 24,
  /** MatchSchoolCoordinatorMadeChanges = 25 */
  MatchSchoolCoordinatorMadeChanges: 25,
  /** MatchStudentAdded = 26 */
  MatchStudentAdded: 26,
  /** MatchStudentRemoved = 27 */
  MatchStudentRemoved: 27,
  /** MatchFacultyAdded = 28 */
  MatchFacultyAdded: 28,
  /** MatchFacultyRemoved = 29 */
  MatchFacultyRemoved: 29,
  /** MatchPreceptorAdded = 30 */
  MatchPreceptorAdded: 30,
  /** MatchPreceptorRemoved = 31 */
  MatchPreceptorRemoved: 31,
  /** MatchApplicationReminder = 53 */
  MatchApplicationReminder: 53,
  /** MatchOnboardingReminder = 54 */
  MatchOnboardingReminder: 54,
  /** MatchOffboardingReminder = 55 */
  MatchOffboardingReminder: 55,
  /** MatchEvaluationReminder = 56 */
  MatchEvaluationReminder: 56,
  /** AgreementSubmitted = 100 */
  AgreementSubmitted: 100,
  /** AgreementAccepted = 101 */
  AgreementAccepted: 101,
  /** AgreementProposedChanges = 102 */
  AgreementProposedChanges: 102,
  /** AgreementClosed = 103 */
  AgreementClosed: 103,
  /** MatchMentioned = 104 */
  MatchMentioned: 104,
  /** MatchClinicalSiteReopened = 105 */
  MatchClinicalSiteReopened: 105,
  /** MatchStudentReopened = 106 */
  MatchStudentReopened: 106,
  /** MatchOnboardingCompleted = 107 */
  MatchOnboardingCompleted: 107,
  /** MatchAddedByClinicalSiteStaff = 108 */
  MatchAddedByClinicalSiteStaff: 108,
  /** MatchSchoolCoordinatorProposedChanges = 110 */
  MatchSchoolCoordinatorProposedChanges: 110,
  /** MatchApplicationStepsChanged = 111 */
  MatchApplicationStepsChanged: 111,
  /** MatchApplicationStepsCompleted = 112 */
  MatchApplicationStepsCompleted: 112,
  /** MatchOffboardingChanged = 113 */
  MatchOffboardingChanged: 113,
  /** MatchOffboardingCompleted = 114 */
  MatchOffboardingCompleted: 114,
  /** MatchStepVerified = 115 */
  MatchStepVerified: 115,
  /** MatchStepRejected = 116 */
  MatchStepRejected: 116,
  /** AgreementExpiring = 117 */
  AgreementExpiring: 117,
  /** MatchClinicalSiteAcceptedFromWaitlist = 118 */
  MatchClinicalSiteAcceptedFromWaitlist: 118,
  /** MatchClinicalSiteAddedToWaitlist = 119 */
  MatchClinicalSiteAddedToWaitlist: 119,
  /** MatchPaused = 120 */
  MatchPaused: 120,
  /** MatchUnpaused = 121 */
  MatchUnpaused: 121,
  /** AgreementCommented = 122 */
  AgreementCommented: 122,
  /** CapacityAdded = 123 */
  CapacityAdded: 123,
  /** CapacityChanged = 124 */
  CapacityChanged: 124,
  /** CapacityCommented = 125 */
  CapacityCommented: 125,
  /** CapacityDeleted = 126 */
  CapacityDeleted: 126,
  /** MatchActiveReminder = 127 */
  MatchActiveReminder: 127,
  /** MatchActiveChanged = 128 */
  MatchActiveChanged: 128,
  /** NewPendingStaff = 129 */
  NewPendingStaff: 129,
  /** NewPendingStudent = 130 */
  NewPendingStudent: 130,
  /** SchoolComplianceStepChanged = 200 */
  SchoolComplianceStepChanged: 200,
  /** SchoolComplianceStepReminder = 201 */
  SchoolComplianceStepReminder: 201,
  /** MatchSchoolComplianceStepChanged = 202 */
  MatchSchoolComplianceStepChanged: 202,
  /** MatchEvaluationChanged = 204 */
  MatchEvaluationChanged: 204,
  /** MatchEvaluationCompleted = 205 */
  MatchEvaluationCompleted: 205,
  /** MatchSchoolComplianceStepReminder = 206 */
  MatchSchoolComplianceStepReminder: 206,
}
/** See source definition here: CN.Infrastructure\Features\Orgs\OrgDomainListProperty.cs */
export const OrgDomainListProperty = {
  /** Automatic = 0 */
  Automatic: 0,
  /** Domain = 10 */
  Domain: 10,
  /** Status = 15 */
  Status: 15,
  /** CountStaff = 20 */
  CountStaff: 20,
  /** CountStudents = 25 */
  CountStudents: 25,
}
/** See source definition here: CN.Infrastructure\Features\Orgs\OrgDomainStatus.cs */
export const OrgDomainStatus = {
  /** Allowed = 1 */
  Allowed: 1,
  /** Proposed = 2 */
  Proposed: 2,
  /** Rejected = 3 */
  Rejected: 3,
  /** Deleted = 4 */
  Deleted: 4,
  /** Blocked = 5 */
  Blocked: 5,
}
addDisplays(OrgDomainStatus, 3, 'Proposed (and rejected)')
/** See source definition here: CN.Infrastructure\Models\Enums\OrgInheritBehavior.cs */
export const OrgInheritBehavior = {
  /** SelfAndDescendants = 0 */
  SelfAndDescendants: 0,
  /** Self = 10 */
  Self: 10,
  /** Descendants = 20 */
  Descendants: 20,
  /** Specific = 255 */
  Specific: 255,
}
/** See source definition here: CN.Infrastructure\ViewModels\Organizations\ListOrgsRequest.cs */
export const OrgListProperty = {
  /** Automatic = 0 */
  Automatic: 0,
  /** Name = 10 */
  Name: 10,
  /** CityState = 20 */
  CityState: 20,
  /** StateCity = 30 */
  StateCity: 30,
  /** PhoneNumber = 40 */
  PhoneNumber: 40,
  /** DateCreated = 50 */
  DateCreated: 50,
  /** StudentCount = 60 */
  StudentCount: 60,
}
/** See source definition here: CN.Infrastructure\Features\Orgs\OrgNavigationStrategy.cs */
export const OrgNavigationStrategy = {
  /** Opportunities = 0 */
  Opportunities: 0,
  /** Services = 1 */
  Services: 1,
  /** Both = 2 */
  Both: 2,
}
/** See source definition here: CN.Infrastructure\Features\OrgRelationships\ListOrgRelationshipsRequest.cs */
export const OrgRelationshipListProperty = {
  /** Default = 0 */
  Default: 0,
  /** OwnerOrgName = 10 */
  OwnerOrgName: 10,
  /** TargetOrgName = 20 */
  TargetOrgName: 20,
  /** AllowCoordinatorScheduling = 30 */
  AllowCoordinatorScheduling: 30,
  /** AllowStudentScheduling = 40 */
  AllowStudentScheduling: 40,
  /** AgreementCount = 50 */
  AgreementCount: 50,
  /** ActiveCapacityCount = 60 */
  ActiveCapacityCount: 60,
  /** RotationCount = 70 */
  RotationCount: 70,
}
/** See source definition here: CN.Infrastructure\Features\Orgs\OrgReportColumn.cs */
export const OrgReportColumn = {
  /** CountDescendantOrgs = 1 */
  CountDescendantOrgs: 1,
  /** CountHostRotations = 2 */
  CountHostRotations: 2,
  /** CountGuestRotations = 3 */
  CountGuestRotations: 3,
  /** CountAgreements = 4 */
  CountAgreements: 4,
  /** CountServices = 5 */
  CountServices: 5,
  /** CountOpportunities = 6 */
  CountOpportunities: 6,
  /** CountSteps = 7 */
  CountSteps: 7,
  /** CountStaff = 8 */
  CountStaff: 8,
  /** CountStudents = 9 */
  CountStudents: 9,
  /** CountStaffVisitsThisMonth = 10 */
  CountStaffVisitsThisMonth: 10,
  /** CountStaffVisitsThisYear = 11 */
  CountStaffVisitsThisYear: 11,
  /** CountStudentVisitsThisMonth = 12 */
  CountStudentVisitsThisMonth: 12,
  /** CountStudentVisitsThisYear = 13 */
  CountStudentVisitsThisYear: 13,
  /** CountDocumentsThisMonth = 14 */
  CountDocumentsThisMonth: 14,
  /** CountDocumentsThisYear = 15 */
  CountDocumentsThisYear: 15,
  /** CountFormsThisMonth = 16 */
  CountFormsThisMonth: 16,
  /** CountFormsThisYear = 17 */
  CountFormsThisYear: 17,
}
/** See source definition here: CN.Infrastructure\Models\Enums\OrgWizardStep.cs */
export const OrgWizardStep = {
  /** CreateProfile = 10 */
  CreateProfile: 10,
  /** Locations = 20 */
  Locations: 20,
  /** Staff = 40 */
  Staff: 40,
  /** Application = 50 */
  Application: 50,
  /** Onboarding = 60 */
  Onboarding: 60,
  /** Offboarding = 70 */
  Offboarding: 70,
  /** Evaluation = 80 */
  Evaluation: 80,
}
/** See source definition here: CN.Infrastructure\Models\Enums\Permission.cs */
export const Permission = {
  /** ManageOrgProfile = 0 */
  ManageOrgProfile: 0,
  /** CoordinateMatches = 1 */
  CoordinateMatches: 1,
  /** CoordinateAllMatches = 2 */
  CoordinateAllMatches: 2,
  /** ImportMatches = 3 */
  ImportMatches: 3,
  /** PreceptMatches = 4 */
  PreceptMatches: 4,
  /** ViewAgreements = 5 */
  ViewAgreements: 5,
  /** ManageAgreements = 6 */
  ManageAgreements: 6,
  /** ViewStaff = 7 */
  ViewStaff: 7,
  /** ManageStaff = 8 */
  ManageStaff: 8,
  /** Reports = 9 */
  Reports: 9,
  /** ViewMatches = 10 */
  ViewMatches: 10,
  /** ManageLocations = 11 */
  ManageLocations: 11,
  /** ManageElectiveSettings = 12 */
  ManageElectiveSettings: 12,
  /** ManageAdvancedSettings = 13 */
  ManageAdvancedSettings: 13,
  /** ViewOpportunitiesAndServices = 14 */
  ViewOpportunitiesAndServices: 14,
  /** CreateOpportunitiesAndServices = 15 */
  CreateOpportunitiesAndServices: 15,
  /** ManageOpportunitiesAndServices = 16 */
  ManageOpportunitiesAndServices: 16,
  /** ViewSteps = 17 */
  ViewSteps: 17,
  /** ManageSteps = 18 */
  ManageSteps: 18,
  /** ManageSchoolStudents = 19 */
  ManageSchoolStudents: 19,
  /** ManageConsortiumMembers = 20 */
  ManageConsortiumMembers: 20,
  /** ViewApplicationStepsReport = 21 */
  ViewApplicationStepsReport: 21,
  /** ViewConsortiumRotations = 22 */
  ViewConsortiumRotations: 22,
  /** ViewConsortiumRelations = 23 */
  ViewConsortiumRelations: 23,
  /** ViewRotationList = 24 */
  ViewRotationList: 24,
  /** ViewStudentList = 25 */
  ViewStudentList: 25,
  /** ViewOffboardingStepsReport = 26 */
  ViewOffboardingStepsReport: 26,
  /** ViewEvaluationStepsReport = 27 */
  ViewEvaluationStepsReport: 27,
  /** ViewOnboardingStepsReport = 28 */
  ViewOnboardingStepsReport: 28,
  /** ViewPerformanceAndUtilizationReport = 29 */
  ViewPerformanceAndUtilizationReport: 29,
  /** ViewRotationBreakdownReport = 30 */
  ViewRotationBreakdownReport: 30,
  /** ViewRotationStatusHistoryReport = 31 */
  ViewRotationStatusHistoryReport: 31,
  /** ViewStudentReport = 32 */
  ViewStudentReport: 32,
  /** BulkActionEmail = 33 */
  BulkActionEmail: 33,
  /** ManageSubscription = 34 */
  ManageSubscription: 34,
  /** ManageDomains = 35 */
  ManageDomains: 35,
  /** ManageTeams = 36 */
  ManageTeams: 36,
  /** ViewTeams = 37 */
  ViewTeams: 37,
  /** CreateApiUsers = 38 */
  CreateApiUsers: 38,
  /** GetApiUsers = 39 */
  GetApiUsers: 39,
  /** UpdateApiUsers = 40 */
  UpdateApiUsers: 40,
  /** ManageCustomTags = 41 */
  ManageCustomTags: 41,
  /** ViewCustomTags = 42 */
  ViewCustomTags: 42,
  /** ManageSecuritySettings = 43 */
  ManageSecuritySettings: 43,
  /** ViewSchoolComplianceStepsReport = 50 */
  ViewSchoolComplianceStepsReport: 50,
  /** ManageBilling = 100 */
  ManageBilling: 100,
  /** CN = 101 */
  CN: 101,
  /** IsMatchStudent = 102 */
  IsMatchStudent: 102,
  /** IsMatchCoordinator = 103 */
  IsMatchCoordinator: 103,
  /** IsMatchSchoolCoordinator = 104 */
  IsMatchSchoolCoordinator: 104,
  /** IsMatchSchoolFaculty = 105 */
  IsMatchSchoolFaculty: 105,
  /** IsMatchPreceptor = 106 */
  IsMatchPreceptor: 106,
  /** IsMatchViewer = 107 */
  IsMatchViewer: 107,
  /** CanDownloadStepAttachment = 108 */
  CanDownloadStepAttachment: 108,
  /** CanDownloadStepData = 109 */
  CanDownloadStepData: 109,
  /** CanDownloadAgreementFile = 110 */
  CanDownloadAgreementFile: 110,
  /** CanAccessService = 111 */
  CanAccessService: 111,
  /** CanAccessCapacity = 112 */
  CanAccessCapacity: 112,
  /** HasPreCheckClientAccess = 114 */
  HasPreCheckClientAccess: 114,
  /** PreCheckApi = 115 */
  PreCheckApi: 115,
  /** ListMatchesApi = 116 */
  ListMatchesApi: 116,
  /** GetMatchDetailsApi = 117 */
  GetMatchDetailsApi: 117,
  /** AddMatchesApi = 118 */
  AddMatchesApi: 118,
  /** ChangeMatchApi = 119 */
  ChangeMatchApi: 119,
  /** CloseMatchApi = 120 */
  CloseMatchApi: 120,
  /** ListStudentsApi = 121 */
  ListStudentsApi: 121,
  /** AddStudentsApi = 122 */
  AddStudentsApi: 122,
  /** ListCapacityApi = 123 */
  ListCapacityApi: 123,
  /** ListDisciplinesApi = 124 */
  ListDisciplinesApi: 124,
  /** ListStepSubmissionsApi = 125 */
  ListStepSubmissionsApi: 125,
  /** PutStepSubmissionApi = 126 */
  PutStepSubmissionApi: 126,
  /** ViewElectiveSettings = 127 */
  ViewElectiveSettings: 127,
  /** RejectStepSubmissionApi = 128 */
  RejectStepSubmissionApi: 128,
  /** GetWebhooks = 129 */
  GetWebhooks: 129,
  /** UpdateStudentsApi = 130 */
  UpdateStudentsApi: 130,
  /** CreateWebhooks = 131 */
  CreateWebhooks: 131,
  /** ViewActiveStepsReport = 132 */
  ViewActiveStepsReport: 132,
  /** CNOrgSubscriptions = 133 */
  CNOrgSubscriptions: 133,
  /** ManageCourses = 134 */
  ManageCourses: 134,
  /** CNFeaturePackages = 135 */
  CNFeaturePackages: 135,
  /** CNImpersonate = 137 */
  CNImpersonate: 137,
  /** CNImpersonateUnsafeDownloadDocuments = 139 */
  CNImpersonateUnsafeDownloadDocuments: 139,
  /** ViewCourses = 140 */
  ViewCourses: 140,
  /** CNMergeOrgs = 141 */
  CNMergeOrgs: 141,
  /** CNMergeUsers = 143 */
  CNMergeUsers: 143,
  /** CNManageUsers = 145 */
  CNManageUsers: 145,
  /** CNViewUsers = 147 */
  CNViewUsers: 147,
  /** CNDev = 149 */
  CNDev: 149,
  /** CNSupport = 150 */
  CNSupport: 150,
  /** CNViewOrgs = 151 */
  CNViewOrgs: 151,
  /** CNManageOrgs = 153 */
  CNManageOrgs: 153,
  /** ViewNameSuggestions = 155 */
  ViewNameSuggestions: 155,
  /** ListStaffApi = 156 */
  ListStaffApi: 156,
  /** ManageNameSuggestions = 157 */
  ManageNameSuggestions: 157,
  /** ViewOrgRelationships = 159 */
  ViewOrgRelationships: 159,
  /** ManageOrgRelationships = 161 */
  ManageOrgRelationships: 161,
  /** ConfirmAndReleaseMatches = 162 */
  ConfirmAndReleaseMatches: 162,
}
addDisplays(Permission, 116, 'List rotations', 117, 'Get rotation details', 118, 'Add rotations', 119, 'Update rotations', 120, 'Close rotations', 121, 'List students', 122, 'Add students', 123, 'List opportunities', 124, 'List disciplines', 125, 'List step submissions', 126, 'Save step submissions', 128, 'Reject step submissions', 130, 'Update students', 156, 'List staff')
/** See source definition here: CN.Infrastructure\Models\Enums\PersonaType.cs */
export const PersonaType = {
  /** Student = 0 */
  Student: 0,
  /** ProviderStaff = 1 */
  ProviderStaff: 1,
  /** SchoolStaff = 2 */
  SchoolStaff: 2,
  /** CN = 3 */
  CN: 3,
  /** ConsortiumStaff = 4 */
  ConsortiumStaff: 4,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\Dates\RangeBound.cs */
export const RangeBound = {
  /** Start = 10 */
  Start: 10,
  /** End = 20 */
  End: 20,
}
/** See source definition here: CN.Infrastructure\Models\Enums\RepeatUnit.cs */
export const RepeatUnit = {
  /** Daily = 3 */
  Daily: 3,
  /** Weekly = 6 */
  Weekly: 6,
  /** Monthly = 9 */
  Monthly: 9,
  /** Yearly = 12 */
  Yearly: 12,
}
/** See source definition here: CN.Infrastructure\Features\Capacities\RequestAvailabilityPolicy.cs */
export const RequestAvailabilityPolicy = {
  /** MultipleAvailableShifts = 1 */
  MultipleAvailableShifts: 1,
  /** SingleAvailableShift = 2 */
  SingleAvailableShift: 2,
}
/** See source definition here: CN.Infrastructure\Models\Enums\RotationListViewType.cs */
export const RotationListViewType = {
  /** Column = 10 */
  Column: 10,
  /** Calendar = 20 */
  Calendar: 20,
  /** List = 30 */
  List: 30,
}
/** See source definition here: CN.Infrastructure\Models\Enums\SavedSearchType.cs */
export const SavedSearchType = {
  /** RotationList = 0 */
  RotationList: 0,
  /** RotationCalendar = 1 */
  RotationCalendar: 1,
  /** CapacityCalendar = 3 */
  CapacityCalendar: 3,
  /** StudentList = 5 */
  StudentList: 5,
  /** RotationStatusHistoryReport = 10 */
  RotationStatusHistoryReport: 10,
  /** RotationBreakdownReport = 20 */
  RotationBreakdownReport: 20,
  /** StudentReport = 30 */
  StudentReport: 30,
  /** HourLogReport = 35 */
  HourLogReport: 35,
  /** ApplicationStepsReport = 40 */
  ApplicationStepsReport: 40,
  /** OnboardingStepsReport = 50 */
  OnboardingStepsReport: 50,
  /** SchoolComplianceStepsReport = 52 */
  SchoolComplianceStepsReport: 52,
  /** ActiveStepsReport = 55 */
  ActiveStepsReport: 55,
  /** OffboardingStepsReport = 60 */
  OffboardingStepsReport: 60,
  /** EvaluationStepsReport = 65 */
  EvaluationStepsReport: 65,
  /** EvaluationsReport = 67 */
  EvaluationsReport: 67,
  /** PerformanceAndUtilizationReport = 70 */
  PerformanceAndUtilizationReport: 70,
  /** ConsortiumRelations = 100 */
  ConsortiumRelations: 100,
  /** ConsortiumRotations = 105 */
  ConsortiumRotations: 105,
  /** MyStepsReport = 120 */
  MyStepsReport: 120,
  /** CNOverview = 1000 */
  CNOverview: 1000,
}
addDisplays(SavedSearchType, 3, 'Opportunity usage report', 10, 'Rotation status history', 20, 'Rotation breakdown', 30, 'Student, faculty, and preceptors report', 35, 'Hours')
/** See source definition here: CN.Infrastructure\Features\OrgServices\ListServicesRequest.cs */
export const ServiceListProperty = {
  /** OrganizationRelativeName = 0 */
  OrganizationRelativeName: 0,
  /** ServiceName = 10 */
  ServiceName: 10,
  /** ActiveCapacityCount = 15 */
  ActiveCapacityCount: 15,
}
/** See source definition here: CN.Infrastructure\Features\Orgs\ServiceNameStrategy.cs */
export const ServiceNameStrategy = {
  /** AllowAnyName = 0 */
  AllowAnyName: 0,
  /** RequireStaffToChooseFromAList = 1 */
  RequireStaffToChooseFromAList: 1,
}
/** See source definition here: CN.Infrastructure\DynamicQuery\Capacity\Filters\CapacityMatchCountsFilter.cs */
export const SimpleMatchCountsOption = {
  /** HasAvailableMatches = 1 */
  HasAvailableMatches: 1,
  /** HasLimitedAvailableMatches = 2 */
  HasLimitedAvailableMatches: 2,
  /** HasNoAvailableMatches = 3 */
  HasNoAvailableMatches: 3,
}
/** See source definition here: CN.Infrastructure\Features\Staff\Requests\ListStaffRequest.cs */
export const StaffListProperty = {
  /** OrganizationRelativeName = 0 */
  OrganizationRelativeName: 0,
  /** StaffName = 10 */
  StaffName: 10,
  /** Title = 15 */
  Title: 15,
  /** ExternalID = 20 */
  ExternalID: 20,
}
/** See source definition here: CN.Infrastructure\Models\Enums\StaffRole.cs */
export const StaffRole = {
  /** Admin = 0 */
  Admin: 0,
  /** Coordinator = 1 */
  Coordinator: 1,
  /** Preceptor = 2 */
  Preceptor: 2,
  /** ReportViewer = 3 */
  ReportViewer: 3,
  /** Faculty = 4 */
  Faculty: 4,
  /** AgreementManager = 5 */
  AgreementManager: 5,
  /** StepManager = 6 */
  StepManager: 6,
  /** CapacityManager = 7 */
  CapacityManager: 7,
}
addDisplays(StaffRole, 7, 'Opportunity manager')
/** See source definition here: CN.Infrastructure\Features\Step\StepAction.cs */
export const StepAction = {
  /** Verify = 0 */
  Verify: 0,
  /** Reject = 1 */
  Reject: 1,
  /** Waive = 2 */
  Waive: 2,
  /** UnWaive = 3 */
  UnWaive: 3,
  /** AddFile = 4 */
  AddFile: 4,
  /** DeleteFile = 5 */
  DeleteFile: 5,
  /** SaveForm = 6 */
  SaveForm: 6,
  /** ToggleCheck = 7 */
  ToggleCheck: 7,
  /** Skip = 8 */
  Skip: 8,
  /** Unskip = 9 */
  Unskip: 9,
  /** SetDueDate = 10 */
  SetDueDate: 10,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepCompletionAssignment.cs */
export const StepCompletionAssignment = {
  /** Any = 0 */
  Any: 0,
  /** Each = 1 */
  Each: 1,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepCompletionTarget.cs */
export const StepCompletionTarget = {
  /** None = 0 */
  None: 0,
  /** EachUserInTargetRoles = 1 */
  EachUserInTargetRoles: 1,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepCompletionTargetSameRoleBehavior.cs */
export const StepCompletionTargetSameRoleBehavior = {
  /** OnlyForThemself = 0 */
  OnlyForThemself: 0,
  /** OnlyForOthersInTheRole = 1 */
  OnlyForOthersInTheRole: 1,
  /** EveryoneInTheRoleIncludingThemself = 2 */
  EveryoneInTheRoleIncludingThemself: 2,
  /** OnlyForOtherRoles = 3 */
  OnlyForOtherRoles: 3,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepCompletionWhen.cs */
export const StepCompletionWhen = {
  /** Anytime = 5 */
  Anytime: 5,
  /** MonthDay = 7 */
  MonthDay: 7,
  /** RotationIsCreated = 10 */
  RotationIsCreated: 10,
  /** StudentSubmitsTheRotation = 20 */
  StudentSubmitsTheRotation: 20,
  /** HostApprovesTheRotation = 30 */
  HostApprovesTheRotation: 30,
  /** RotationStarts = 50 */
  RotationStarts: 50,
  /** RotationEnds = 60 */
  RotationEnds: 60,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepIncompleteAction.cs */
export const StepIncompleteAction = {
  /** WarnOnly = 10 */
  WarnOnly: 10,
  /** CloseRotation = 20 */
  CloseRotation: 20,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepInputType.cs */
export const StepInputType = {
  /** ChecklistItem = 0 */
  ChecklistItem: 0,
  /** FileUpload = 1 */
  FileUpload: 1,
  /** Form = 2 */
  Form: 2,
  /** CustomIntegration = 5 */
  CustomIntegration: 5,
  /** PreCheck = 10 */
  PreCheck: 10,
}
addDisplays(StepInputType, 10, 'PreCheck')
/** See source definition here: CN.Infrastructure\Features\Step\ListStepsRequest.cs */
export const StepListProperty = {
  /** OrganizationRelativeName = 0 */
  OrganizationRelativeName: 0,
  /** Name = 10 */
  Name: 10,
  /** InputType = 15 */
  InputType: 15,
  /** FileCount = 20 */
  FileCount: 20,
  /** Anonymize = 25 */
  Anonymize: 25,
  /** Deleted = 30 */
  Deleted: 30,
  /** Archived = 35 */
  Archived: 35,
}
/** See source definition here: CN.Infrastructure\Features\StepSubmission\Requests\ListStepSubmissionRequest.cs */
export const StepSubmissionListSortProperty = {
  /** CompleteToDate = 0 */
  CompleteToDate: 0,
  /** SubmissionStatus = 10 */
  SubmissionStatus: 10,
  /** Step = 20 */
  Step: 20,
  /** CompleterUserName = 30 */
  CompleterUserName: 30,
}
/** See source definition here: CN.Infrastructure\Features\StepSubmission\StepSubmissionStatus.cs */
export const StepSubmissionStatus = {
  /** Incomplete = 0 */
  Incomplete: 0,
  /** AwaitingVerification = 1 */
  AwaitingVerification: 1,
  /** Rejected = 2 */
  Rejected: 2,
  /** Completed = 3 */
  Completed: 3,
  /** Waived = 4 */
  Waived: 4,
  /** Verified = 5 */
  Verified: 5,
  /** Skipped = 6 */
  Skipped: 6,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepType.cs */
export const StepType = {
  /** Application = 0 */
  Application: 0,
  /** Onboarding = 1 */
  Onboarding: 1,
  /** Offboarding = 2 */
  Offboarding: 2,
  /** Evaluation = 3 */
  Evaluation: 3,
  /** Active = 4 */
  Active: 4,
  /** SchoolCompliance = 7 */
  SchoolCompliance: 7,
}
/** See source definition here: CN.Infrastructure\Features\StepSubmission\StepVerificationStatus.cs */
export const StepVerificationStatus = {
  /** AwaitingVerification = 0 */
  AwaitingVerification: 0,
  /** Verified = 1 */
  Verified: 1,
  /** Rejected = 2 */
  Rejected: 2,
}
/** See source definition here: CN.Infrastructure\Features\Step\StepVerificationType.cs */
export const StepVerificationType = {
  /** NoVerificationRequired = 0 */
  NoVerificationRequired: 0,
  /** AnyClinicalSiteCoordinator = 1 */
  AnyClinicalSiteCoordinator: 1,
  /** AnySchoolCoordinator = 2 */
  AnySchoolCoordinator: 2,
  /** AnySchoolFaculty = 3 */
  AnySchoolFaculty: 3,
  /** SpecificUsers = 5 */
  SpecificUsers: 5,
}
/** See source definition here: CN.Infrastructure\Features\Account\ListStudentRequest.cs */
export const StudentListProperty = {
  /** Name = 0 */
  Name: 0,
  /** YearOfStudy = 10 */
  YearOfStudy: 10,
  /** GraduationDate = 15 */
  GraduationDate: 15,
  /** Discipline = 20 */
  Discipline: 20,
  /** SchoolRelativeName = 25 */
  SchoolRelativeName: 25,
  /** EmailAddress = 30 */
  EmailAddress: 30,
  /** DateTimeAdded = 35 */
  DateTimeAdded: 35,
}
/** See source definition here: CN.Infrastructure\Models\Enums\SubscriptionStatus.cs */
export const SubscriptionStatus = {
  /** Trial = 0 */
  Trial: 0,
  /** Active = 20 */
  Active: 20,
  /** Expired = 40 */
  Expired: 40,
  /** Canceled = 50 */
  Canceled: 50,
}
/** See source definition here: CN.Infrastructure\Models\Enums\ToManyComparison.cs */
export const ToManyComparison = {
  /** AnyOf = 10 */
  AnyOf: 10,
  /** AllOf = 20 */
  AllOf: 20,
  /** NoneOf = 30 */
  NoneOf: 30,
}
/** See source definition here: CN.Infrastructure\Features\UserDocuments\ListUserDocumentsRequest.cs */
export const UserDocumentListProperty = {
  /** Default = 0 */
  Default: 0,
  /** FileType = 10 */
  FileType: 10,
  /** NameOriginal = 20 */
  NameOriginal: 20,
  /** Size = 30 */
  Size: 30,
  /** DateCreated = 40 */
  DateCreated: 40,
}
/** See source definition here: CN.Infrastructure\Models\Enums\UserRelationship.cs */
export const UserRelationship = {
  /** Colleague = 0 */
  Colleague: 0,
  /** Student = 1 */
  Student: 1,
  /** Preceptor = 2 */
  Preceptor: 2,
  /** Teacher = 3 */
  Teacher: 3,
  /** Coordinator = 4 */
  Coordinator: 4,
  /** Other = 5 */
  Other: 5,
  /** None = 6 */
  None: 6,
}
/** See source definition here: CN.Infrastructure\Models\Enums\ViewPickerOption.cs */
export const ViewPickerOption = {
  /** Mine = 0 */
  Mine: 0,
  /** SharedWithMe = 1 */
  SharedWithMe: 1,
  /** All = 2 */
  All: 2,
}