<!-- Changing something in here? Consider making a similar change to CapacityHostView. -->

<Form on:submit={submit} on:invalidSubmit={onInvalidSubmit} {validationMessagesByFormGroupByFormSection}>
  <Router>
    <CapacityView {capacity} {saving} bind:tabName bind:tabs>
      <Route>
        <!--
        We currently don't allow changing the owner org/team after creation
        This is definitely something we could support, but we'd have to think through security/privacy
        as well as how to handle existing matches (maybe some statuses should block changes, others shouldn't)
      -->
        <div class="flex-row flex-align-top g3">
          <div class="flex-grow">
            {#if input.capacityId}
              <div class="mb2">
                <label for="capacity-owner-org">
                  Owner location {#if input.teamId}/ team{/if}
                </label>

                <OrgAndTeamProfilePictureAndName
                  orgName={input.orgName}
                  orgProfilePicture={input.orgProfilePicture}
                  orgRelativeName={input.orgRelativeName}
                  teamName={input.teamName}
                  teamOrgId={input.teamOrgId}
                  dataTest="capacity-owner-org"
                />
              </div>
            {:else}
              <OwnerOrgTeamPicker
                bind:orgId={input.orgId}
                bind:teamId={input.teamId}
                bind:selectedOrg={_selectedOrg}
                bind:selectedTeam={_selectedTeam}
                bind:hasTeamOptions
                requiredPermission={Permission.ManageOpportunitiesAndServices}
                {onDefaultOrgId}
                {onDefaultTeamId}
                includeAncestorOrgIds
              />
            {/if}

            <FormGroup>
              <label class="flex-row flex-align-center g05" for="capacity-locations">
                <Icon name="hospital" class="color-text-orange" />
                Where is this opportunity?
              </label>
              <SimpleOrgPicker
                bind:value={input.locationIds}
                multiple
                ancestorOrgId={$persona.orgId}
                placeholder="Select location(s)"
                name="capacity-locations"
              />
            </FormGroup>

            <FormGroup
              valid={validationMessagesByFormGroupByFormSection.general.name == null}
              validationMessage={validationMessagesByFormGroupByFormSection.general.name}
            >
              <div class="flex-row flex-align-center g05 mb05">
                <label class="flex-row flex-align-center g05 m0" for="capacity-name">
                  <Icon name="list" class="color-text-purple" />
                  Opportunity name<RequiredMarker />
                </label>
                <Help tipOptions={{ maxWidth: 350 }}>A name for this opportunity.<br />For example, “Spring electives” vs. “Fall electives”.</Help>
              </div>
              <InputText name="capacity-name" bind:value={input.name} autofocus={input.capacityId == null} />
            </FormGroup>

            <FormGroup>
              <div class="flex-row flex-align-center g05 mb05">
                <label class="m0" for="capacity-description">Opportunity description</label>
                <Help>Optionally provide more details about this opportunity.</Help>
              </div>
              <InputTextarea name="capacity-description" bind:value={input.description} />
            </FormGroup>

            <!-- moving service picker when editing from agreements below name as eventually it will be an optional thing... -->
            <!-- TODO(services): test that no services field shows up if none exist -->

            <FormGroup
              valid={validationMessagesByFormGroupByFormSection.general.serviceId == null}
              validationMessage={validationMessagesByFormGroupByFormSection.general.serviceId}
            >
              <label class="flex-row flex-align-center g05" for="service-id">
                <Icon name="shapes" class="color-text-purple" />
                Which service is this in?
              </label>
              <!-- TODO(services): Support a name/id for a11y and labels on this component somehow... -->

              {#if serviceIsNotValid}
                <Alert type="warning">This opportunity’s service isn’t included in the agreement’s services.</Alert>
              {/if}
              <ServicePicker
                bind:value={input.serviceId}
                bind:selectedService={_selectedService}
                allowSelectNull
                modalTitle="Which service is this opportunity in?"
                filters={defaultServiceFilter}
                disabled={isServiceView}
                interceptors={serviceAndAgreementPickerInterceptors}
                class="form-control-sm-min"
              />

              {#if removedSpecialties.length}
                <div class="hide">
                  <div bind:this={removedSpecialtiesTooltipElem}>
                    <h4 class="leading-none mb05">Removed:</h4>
                    <div class="flex-row flex-align-center g05">
                      {#each removedSpecialties as specialty}
                        <span class="btn btn-no-hover btn-sm btn-info">{specialty}</span>
                      {/each}
                    </div>
                  </div>
                </div>

                <Alert type="warning" class="mt1">
                  We automatically removed <span
                    class="has-tooltip"
                    use:tip={{ content: removedSpecialtiesTooltipElem, options: { theme: 'light-gray-scrollable' } }}
                    >{pluralCount('selected specialty', removedSpecialties)}</span
                  >
                  because {#if removedSpecialties.length === 1}it’s{:else}they’re{/if}
                  not valid for the selected service.
                </Alert>
              {/if}

              {#if removedDisciplines.length && fromTab === 'general'}
                <div class="hide">
                  <div bind:this={removedDisciplinesTooltipElem}>
                    <h4 class="leading-none mb05">Removed:</h4>
                    <FriendlyList items={removedDisciplines} max={Infinity} punctuation />
                  </div>
                </div>

                <Alert type="warning" class="mt1">
                  We automatically removed <span
                    class="has-tooltip"
                    use:tip={{ content: removedDisciplinesTooltipElem, options: { theme: 'light-gray-scrollable' } }}
                    >{pluralCount('selected discipline', removedDisciplines)}</span
                  >
                  because {#if removedDisciplines.length === 1}it’s{:else}they’re{/if}
                  not available for the selected service.
                  <a href={null} on:click={() => (tabName = 'schools')}>Edit opportunity disciplines</a>
                </Alert>
              {/if}
            </FormGroup>

            <FormGroup>
              <div class="flex-row flex-align-center g05 mb05">
                <label class="flex-row flex-align-center g05 m0" for="rotation-types">
                  <Icon name="briefcase-medical" class="text-rotation-type" />
                  Experience types
                </label>
                <Help tipOptions={{ maxWidth: 300 }}>To help coordinators and applicants find the right opportunities, select any that apply.</Help>
              </div>
              <RotationTypesPicker name="rotation-types" bind:value={input.rotationTypes} placeholder="None selected" />
            </FormGroup>

            <FormGroup>
              <div class="flex-row flex-align-center g05 mb05">
                <label class="flex-row flex-align-center g05 m0" for="specialties">
                  <Icon name="file-certificate" class="text-specialty" />
                  Specialties
                </label>
                <Help tipOptions={{ maxWidth: 300 }}>
                  To help coordinators and applicants find the right opportunities, select any specialties this opportunity involves or focuses on.
                </Help>
              </div>

              <InheritedTagPicker
                bind:value={input.specialties}
                type="specialty"
                inheritedFromSources={[
                  {
                    type: 'service',
                    icon: 'shapes',
                    iconProps: { class: 'color-text-purple' },
                    name: selectedService?.name,
                    items: selectedService ? selectedService.specialties : capacity.serviceSpecialties,
                  },
                ]}
                let:subsetOfInherited
                let:placeholder
                let:enabledOptions
                let:userExplicitlySelectedAllInherited
              >
                {#if subsetOfInherited}
                  <InputSelect bind:value={input.specialties} options={enabledOptions} multiple let:option {placeholder}>
                    <svelte:fragment slot="prefix">
                      {#if input.specialties.length}
                        {#if userExplicitlySelectedAllInherited}
                          Use
                        {:else}
                          Only use
                        {/if}
                      {/if}
                    </svelte:fragment>
                    <span class="btn btn-no-hover btn-sm btn-info">{option}</span>
                  </InputSelect>
                {:else}
                  <SpecialtiesPicker bind:value={input.specialties} placeholder="None selected" />
                {/if}
              </InheritedTagPicker>
            </FormGroup>

            {#if input.postToSearchPage}
              <div class="mt2">
                <Collapsible light label="staff" name="capacity-staff" open>
                  <span slot="label" class="small em"
                    >{staffCount} staff
                    {#if staffCount}
                      <div class="flex-row g05">
                        {#each input.staff as staff}
                          <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} medium />
                        {/each}
                      </div>
                    {/if}
                  </span>
                  <h3>
                    <Icon name={$persona.orgIcon} sm /> Staff visible to applicants
                    <Badge count={staffCount} />
                  </h3>
                  {#if staffCount}
                    <div class="flex-row flex-wrap">
                      {#each input.staff as staff}
                        <StaffCard
                          bind:staff
                          capacity={input}
                          onDelete={onRemoveStaff}
                          editable={canEditCapacity}
                          canManageStaff={canEditCapacity}
                          isCapacityStaff
                        />
                      {/each}
                    </div>
                  {/if}
                  {#if canEditCapacity}
                    <CapacityStaffPicker
                      bind:capacity={input}
                      teamName={input.teamName || _selectedTeam?.name}
                      serviceName={_selectedService?.name}
                      orgName={input.orgName || _selectedOrg?.name}
                      excludeStaffIds={input.staff?.map(s => s.staffId)}
                      name="staff-member-id"
                      dataTest="staff-member"
                      modalTitle="Add staff"
                      multiple
                      includeParents
                    />
                  {/if}
                </Collapsible>
              </div>
            {/if}
            <FormGroup>
              <div class="flex g05 mb05">
                <Icon lg name="calendar-clock" class="color-text-dark-green" />
                <label class="m0" for="lead-time">Lead time</label>
                <Help tipOptions={{ maxWidth: 385 }}>
                  During an open opportunity, this option restricts users from requesting rotations that start within the specified number of days
                  from the day that the rotation is requested on.
                  <br /><br />
                  For example, if the opportunity was open and you put 7 days it would not allow any rotations to start until after {oneWeekFromToday}
                  (which is 7 days from today).
                </Help>
              </div>

              <InputNumberWithUnit
                bind:value={input.matchLeadTimeDays}
                name="lead-time"
                min={0}
                max={999}
                class="input-group-inline-grid"
                let:focus
                autocomplete="off"
              />
            </FormGroup>
          </div>

          <div class="flex-align-self-start flex-justify-center flex-align-center p2 mx0 rounded-lg visibility-settings">
            <div class="name h3 text-center strong">Visibility & search settings</div>
            <div class="p1 text-center" data-test="scheduling-status">
              <div class="p">
                {#if input.openForScheduling}
                  <Icon x3 name="eye" class="text-success" dataTest="open-for-scheduling-icon" />
                {:else}
                  <Icon x3 name="hide" class="text-danger" dataTest="not-open-for-scheduling-icon" />
                {/if}
              </div>

              This opportunity
              {#if changedOrNotOpenForScheduling}
                will
              {:else}
                is
              {/if}

              {#if input.openForScheduling}
                {#if changedOrNotOpenForScheduling}
                  be
                {/if}
                <strong class="text-success uppercase">visible</strong>
              {:else}
                <strong class="text-danger uppercase">
                  not
                  {#if changedOrNotOpenForScheduling}
                    be
                  {/if}
                  visible
                </strong>
              {/if}

              <FormGroup class="mt1">
                <div class="inline-flex-row">
                  <label for="allow-new-requests" class={input.openForScheduling ? 'normal' : ''}>
                    <Icon name="check" class="color-text-black" /> Draft
                  </label>
                  <InputToggle name="allow-new-requests" bind:checked={input.openForScheduling} />
                  <label for="allow-new-requests" class={input.openForScheduling ? '' : 'normal'}>Public (visible)</label>
                </div>
              </FormGroup>
            </div>
            <FormGroup class="mb0">
              Rotation scheduling options
              <RequiredMarker />
              <InputRadioGroup bind:value={allowOverlap} options={[{ value: true }, { value: false }]} lastOptionClass="mb0" let:option>
                {#if option.value}
                  <Icon name="overlap" class="color-text-black" /> Allow overlap
                {:else}
                  <Icon name="bars" class="color-text-black" /> No new requests when full
                {/if}

                <HelpBlock slot="after-label" class="ml2 my0" on:click={() => (allowOverlap = option.value)}>
                  {#if option.value}
                    Rotations can be requested regardless of if the given day’s max has been met with any rotation status.
                  {:else}
                    Rotations can be requested until a given day’s max has been met with rotations in a certain status.
                  {/if}
                </HelpBlock>
              </InputRadioGroup>
            </FormGroup>

            <FormGroup
              class="ml3"
              valid={allowOverlap || input.noNewMatchesForDayWhenOthersHaveBeen != null}
              validationMessage="Please select a rotation status."
            >
              <InputSelect
                bind:value={input.noNewMatchesForDayWhenOthersHaveBeen}
                name="rotation-scheduling-status"
                disabled={allowOverlap}
                resetDirtyWhenDisabled
                placeholder="Rotation status"
                options={enumToOptions(MatchHasBeen)}
              />
            </FormGroup>

            <hr class="my3" />

            <div class="mb2">
              <FormGroup class="mb2">
                <InputCheckbox
                  bind:checked={input.postToSearchPage}
                  labelClass="flex-row flex-align-start-force g05 m0 normal"
                  class="checkbox-align-on-top"
                  name="post-to-search-page"
                >
                  <Icon name="search" class="color-text-black" />
                  Allow students to search and apply from the <strong class="strongish">Find clinical experiences</strong> page
                </InputCheckbox>
              </FormGroup>
              <FormGroup class="ml2">
                <InputCheckbox
                  bind:checked={input.allowStudentScheduling}
                  disabled={!input.postToSearchPage}
                  name="allow-student-scheduling"
                  labelClass="flex-row flex-align-start-force g05 m0 normal"
                >
                  Allow students to set a detailed schedule (times & dates)
                </InputCheckbox>
              </FormGroup>
            </div>

            <hr class="my3" />

            <div class="mb2">
              <FormGroup valid={validationMessagesByFormGroupByFormSection.general.scheduling == null} dataTest="capacity-search-settings">
                <svelte:fragment slot="validationMessage">
                  You must either allow coordinators to schedule or allow students to search and apply from the find clinician experiences page.
                </svelte:fragment>

                <InputCheckbox
                  title={input.allowGroups ? { content: 'Group rotations must allow coordinator scheduling', options: { placement: 'left' } } : null}
                  bind:checked={input.allowCoordinatorScheduling}
                  disabled={input.allowGroups}
                  name="allow-coordinator-scheduling"
                  class="flex-row"
                  labelClass="flex-row flex-align-start-force g05 m0 normal"
                >
                  <Icon name="plus" class="color-text-black" /> Allow
                  <span class="has-tooltip text-info" use:tip={{ content: 'From your organization or an added school', options: { theme: 'help' } }}>
                    coordinators
                  </span>
                  to schedule rotations for students
                  {#if !canScheduleCoreRotations && input.allowCoordinatorScheduling}
                    <span class="ml2"><RequiresUpgrade /></span>
                  {/if}
                </InputCheckbox>
              </FormGroup>

              <FormGroup class="ml2">
                <InputCheckbox
                  bind:checked={input.allowHostCoordinatorScheduling}
                  name="allow-host-coordinator-scheduling"
                  disabled={!input.allowCoordinatorScheduling}
                  class="flex-row g05"
                  labelClass="flex-row flex-align-start-force g05 m0 normal"
                >
                  Allow clinical site coordinators to set a detailed<br />
                  schedule (times & dates)
                </InputCheckbox>
              </FormGroup>

              <FormGroup class="ml2">
                <InputCheckbox
                  bind:checked={input.allowGuestCoordinatorScheduling}
                  name="allow-guest-coordinator-scheduling"
                  class="flex-row flex-align-center g05"
                  disabled={!input.allowCoordinatorScheduling}
                  labelClass="flex-row flex-align-start-force g05 m0 normal"
                >
                  Allow school coordinators to set a detailed schedule (times & dates)
                </InputCheckbox>
              </FormGroup>

              <FormGroup class="ml2">
                <InputCheckbox
                  bind:checked={input.allowStudentChanges}
                  name="allow-student-changes"
                  class="flex-row g05"
                  disabled={!input.allowCoordinatorScheduling}
                  labelClass="flex-row flex-align-start-force g05 m0 normal"
                >
                  Allow students to request schedules changes when they’ve been added by someone else
                </InputCheckbox>
              </FormGroup>

              {#if input.allowGroups}
                <FormGroup>
                  <InputCheckbox
                    bind:checked={input.allowStudentsToLeaveGroupMatch}
                    name="allow-student-leave-group-rotation"
                    class="flex-row"
                    labelClass="flex-row flex-align-start-force g05 m0 normal"
                  >
                    <Icon name="users" class="color-text-black" /> Don't allow students to leave group rotations once they’ve been added
                  </InputCheckbox>
                </FormGroup>
              {/if}
            </div>
          </div>
        </div>
      </Route>

      <Route path="schools/*">
        <p>
          By default, anyone can
          {#if input.postToSearchPage === input.allowCoordinatorScheduling}
            apply to or be scheduled for
          {:else if input.postToSearchPage}
            apply to
          {:else}
            be scheduled for
          {/if}
          this opportunity. Use the fields below to limit scheduling to specific schools or disciplines.
        </p>

        {#if input.postToSearchPage}
          <!-- TODO(stripe): did we make elective settings a higher paid feature?  if so, either link to the page anyway and upsell on it, or do a featuretype check here -->
          {#if personaService.hasStaffPermission(Permission.ViewElectiveSettings, $persona.orgId)}
            <p>
              You can block or allow applicants from specific schools on the
              <span class="nowrap">
                <IconTextLink icon="elective-settings" text="Elective request settings" href="/settings/elective-request-settings" />
                page.
              </span>
            </p>
          {/if}
        {/if}

        <div class="flex-row g3">
          <div>
            <h3>Student requirements</h3>

            <div class="flex-row flex-align-top g3">
              <FormGroup>
                <div class="flex-row flex-align-center g05 mb05">
                  <label class="flex-row flex-align-center g05 m0" for="student-year">
                    <Icon name="calendars" class="color-text-teal" />
                    Select student year
                  </label>
                </div>
                <StudentYearPicker name="student-years" bind:value={input.studentYears} multiple />
              </FormGroup>

              <FormGroup
                valid={validationMessagesByFormGroupByFormSection.schools.disciplines == null}
                validationMessage={validationMessagesByFormGroupByFormSection.schools.disciplines}
              >
                <label class="flex-row flex-align-center g05 mb05">
                  <Icon name="book" class="color-text-teal" />
                  Accepted disciplines <RequiredMarker />
                </label>
                <InputRadioGroup
                  options={[{ value: true }, { value: false }]}
                  bind:value={usesCustomDisciplines}
                  let:option
                  disabled={invalidOpportunityDisciplines}
                  name="capacity-disciplines"
                >
                  {#if option.value}
                    Set custom disciplines for this opportunity
                    <DisciplinesPicker
                      bind:value={input.disciplines}
                      enabledOptions={availableDisciplines}
                      hideDisabledOptions
                      disabled={invalidOpportunityDisciplines}
                      on:close={() => (usesCustomDisciplines = !!input.disciplines.length)}
                    />
                  {:else}
                    Inherit disciplines from school relationships
                  {/if}
                </InputRadioGroup>

                {#if removedDisciplines.length && fromTab === 'schools'}
                  <div class="hide">
                    <div bind:this={removedDisciplinesTooltipElem}>
                      <h4 class="leading-none mb05">Removed:</h4>
                      <FriendlyList items={removedDisciplines} max={Infinity} punctuation />
                    </div>
                  </div>

                  <Alert type="warning" class="mt1" timeToLive={5000}>
                    We automatically removed <span
                      class="has-tooltip"
                      use:tip={{ content: removedDisciplinesTooltipElem, options: { theme: 'light-gray-scrollable' } }}
                      >{pluralCount('selected discipline', removedDisciplines)}</span
                    >
                    because {#if removedDisciplines.length === 1}it’s{:else}they’re{/if}
                    not available for the selected agreements.
                  </Alert>
                {/if}

                {#if invalidOpportunityDisciplines || agreementsWithoutDisciplines.length}
                  <div class="hide">
                    <div class="flex-row flex-align-center g05" bind:this={serviceTooltipElem}>
                      <Icon name="shapes" class="color-text-purple" />
                      <span>{selectedService?.name}</span>
                    </div>
                  </div>

                  <div class="hide">
                    <div class="flex-column g05" bind:this={agreementsWithoutDisciplinesTooltipElem}>
                      <FriendlyList items={agreementsWithoutDisciplines} max={Infinity} let:item={agreement}>
                        <AgreementProfilePicturesAndName
                          name={agreement.name}
                          orgName={agreement.orgName}
                          orgRelativeName={agreement.orgRelativeName}
                          orgProfilePicture={agreement.orgProfilePicture}
                          teamName={agreement.teamName}
                          teamOrgId={agreement.teamOrgId}
                          schoolName={agreement.schoolName}
                          schoolRelativeName={agreement.schoolRelativeName}
                          schoolProfilePicture={agreement.schoolProfilePicture}
                          schoolTeamName={agreement.schoolTeamName}
                          schoolTeamOrgId={agreement.schoolTeamOrgId}
                        />
                      </FriendlyList>
                    </div>
                  </div>

                  <div class="hide">
                    <div class="flex-column g05" bind:this={guestsWithoutDisciplinesTooltipElem}>
                      <FriendlyList items={guestsWithoutDisciplines} max={Infinity} let:item={guest}>
                        <OrgProfilePictureAndName
                          name={guest.guestOrgName}
                          relativeName={guest.guestOrgRelativeName}
                          profilePicture={guest.guestOrgProfilePicture}
                        />
                      </FriendlyList>
                    </div>
                  </div>

                  {#if invalidOpportunityDisciplines}
                    <Alert type="warning" class="mt1">
                      The <span class="has-tooltip" use:tip={{ content: serviceTooltipElem, options: { theme: 'light-gray-scrollable' } }}>
                        selected service
                      </span>
                      and
                      <span
                        class="has-tooltip"
                        use:tip={{ content: agreementsWithoutDisciplinesTooltipElem, options: { theme: 'light-gray-scrollable', interactive: true } }}
                      >
                        {pluralCount('agreement', agreementsWithoutDisciplines, input.guests.length === 1 ? 'omitNumber' : 'number')}
                      </span> don’t have accepted disciplines in common.
                    </Alert>
                  {:else}
                    <Alert type="warning" class="mt1">
                      <span
                        class="has-tooltip"
                        use:tip={{ content: guestsWithoutDisciplinesTooltipElem, options: { theme: 'light-gray-scrollable' } }}
                      >
                        {pluralCount('school', guestsWithoutDisciplines)}
                      </span>
                      {#if guestsWithoutDisciplines.length === 1}
                        doesn’t
                      {:else}
                        don’t
                      {/if}
                      have any valid disciplines selected.
                    </Alert>
                  {/if}
                {/if}
              </FormGroup>
            </div>
          </div>
          <div class="ml2">
            <h3>Opportunity rotations available</h3>
            <FormGroup valid={input.maxMatches == null || validator.int(input.maxMatches)}>
              <label for="max-matches-capacity">Rotations available to all schools</label>

              <InputOptionalNumber
                name="max-matches-capacity"
                valueLabelPostfix={pluralCount('max rotation', input.maxMatches, 'omitNumber')}
                bind:value={input.maxMatches}
                min={1}
                noValueLabelClass="flex-row flex-align-center g05"
                noValueIsNull
                width={200}
              >
                <svelte:fragment slot="no-value-label">
                  <Icon lg name="infinity" class="text-info" />
                  <span>Unlimited</span>
                </svelte:fragment>
              </InputOptionalNumber>
            </FormGroup>
          </div>
        </div>

        <h3>School/agreement requirements</h3>

        <Btn icon="plus" class="btn btn-sm btn-primary mb1" href="schools/+" dataTest="add-capacity-guest">Add opportunity school</Btn>
        <CapacityGuestGrid {baseHref} bind:capacity={input} />

        <Router>
          <Route path=":subpath/*" let:params>
            <CapacityGuestFormModal
              baseHref="{baseHref}/schools"
              subpath={params.subpath}
              capacity={input}
              {selectedService}
              {defaultServiceAndAgreementFilter}
              {orgSettings}
              bind:needOrgSettings={hasNeededOrgSettings}
              bind:guests={input.guests}
              bind:shifts={input.shifts}
            />
          </Route>
        </Router>
      </Route>

      <Route path="scheduling">
        <div class="flex-row g2">
          <FormGroup>
            <label for="capacity-dates-start">Overall opportunity dates <RequiredMarker /></label>
            <DatePicker
              name="capacity-dates"
              range
              autoSetOther={false}
              iconClass="color-text-dark-green"
              placeholder="Start date"
              endPlaceholder="End date (optional)"
              bind:value={input.startDate}
              bind:endValue={input.endDate}
            >
              <svelte:fragment slot="rangeEndLabel">
                <span>to</span>
                <Icon name="calendar" class="color-text-light-red" />
              </svelte:fragment>
            </DatePicker>
            <HelpBlock>
              Leave the <Icon name="calendar" class="color-text-light-red" /> <strong>End date</strong> empty to keep the opportunity open indefinitely.
            </HelpBlock>
          </FormGroup>

          <FormGroup>
            <label for="verification-type">
              How should scheduling work for schools who have access to multiple Availability windows?
              <RequiredMarker />
            </label>
            <div class="flex-row flex-align-center g05">
              <span>Schools can schedule into</span>
              <InputSelect sm options={Object.values(RequestAvailabilityPolicy)} bind:value={input.requestAvailabilityPolicy} let:option>
                <div class="full-width flex-row flex-align-center g1">
                  {#if option === RequestAvailabilityPolicy.MultipleAvailableShifts}
                    <span class="flex-grow">multiple windows</span>
                    <Help>
                      Users who are allowed to set a detailed schedule can optionally schedule within the available dates of any of the windows they
                      have access to.
                    </Help>
                  {:else if option === RequestAvailabilityPolicy.SingleAvailableShift}
                    <span class="flex-grow">a single window</span>
                    <Help>
                      Users will be asked to pick a window and if they’re allowed to set a detailed schedule, they can optionally schedule within its
                      available dates.
                    </Help>
                  {/if}
                </div>
              </InputSelect>
              that they have access to.
            </div>
          </FormGroup>
        </div>

        <FormGroup>
          <div class="well">
            <CapacityShiftsForm bind:capacity={input} />
          </div>
        </FormGroup>
      </Route>

      <svelte:fragment slot="footer">
        <Btn icon="chevron-left" on:click={tabs.back} disabled={tabName === 'general'}>Back</Btn>
        <Btn iconRight="chevron-right" on:click={tabs.next} disabled={tabName === 'scheduling'}>Next</Btn>
        <div class="flex-grow" />
        <SubmitBtn icon="save" class="btn-primary" disabled={!hasChanges} loading={saving} dataTest="save-capacity-btn">Save</SubmitBtn>
        <Btn icon="close" dataTest="cancel" on:click={onClose}>Cancel</Btn>
      </svelte:fragment>
    </CapacityView>
  </Router>
</Form>

<script>
  import { FeatureType, FilterType, Permission, RequestAvailabilityPolicy, MatchHasBeen } from 'config/enums.js'
  import { getFlatAncestors } from 'services/orgs-service.js'
  import { pluralCount, toUpper } from 'services/string-utils.js'
  import { Router, Route } from 'svelte-routing'
  import AgreementProfilePicturesAndName from 'components/AgreementProfilePicturesAndName.svelte'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityGuestFormModal from 'components/CapacityGuestFormModal.svelte'
  import CapacityGuestGrid from 'components/CapacityGuestGrid.svelte'
  import CapacityShiftsForm from 'components/CapacityShiftsForm.svelte'
  import CapacityStaffPicker from 'components/CapacityStaffPicker.svelte'
  import CapacityView from 'components/CapacityView.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import confirms from 'stores/confirms.js'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import dateService from 'services/date-service.js'
  import DisciplinesPicker from 'components/fields/DisciplinesPicker.svelte'
  import enumToOptions from 'services/enum-to-options.js'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Help from 'components/Help.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import InheritedTagPicker from './InheritedTagPicker.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputNumberWithUnit from 'components/InputNumberWithUnit.svelte'
  import InputOptionalNumber from 'components/InputOptionalNumber.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import OrgAndTeamProfilePictureAndName from 'components/OrgAndTeamProfilePictureAndName.svelte'
  import OwnerOrgTeamPicker from 'components/OwnerOrgTeamPicker.svelte'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import personaService from 'services/persona-service.js'
  import ProfilePic from 'components/ProfilePic.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import RequiresUpgrade from 'components/RequiresUpgrade.svelte'
  import RotationTypesPicker from 'components/fields/RotationTypesPicker.svelte'
  import ServicePicker from 'components/fields/ServicePicker.svelte'
  import SimpleOrgPicker from 'components/SimpleOrgPicker.svelte'
  import SpecialtiesPicker from 'components/fields/SpecialtiesPicker.svelte'
  import StaffCard from 'components/StaffCard.svelte'
  import StudentYearPicker from 'components/fields/StudentYearPicker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import tip from 'decorators/tip.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'

  export let tabName = 'general'
  export let saving = false
  export let capacity = null
  export let serviceId = null
  export let allowedServiceIds = []
  export let onClose = _.noop
  export let saveCapacity = _.noop
  export let onAfterCapacitySaved = _.noop
  export let ancestorOrgsById = {}
  export let baseHref

  const form = 'CapacityForm'
  const unset = Symbol.for('unset')
  const allTeams = { teamId: null }
  const oneWeekFromToday = dayjs().add(7, 'days').format('M/D/YYYY')
  const serviceAndAgreementPickerInterceptors = {
    [FilterType.HostOrgs]: {
      async addFilterOptions(options) {
        if (options == null) return null
        if (selectedOrg.ancestors) return ensureOrgOptionsIncluded([...selectedOrg.ancestors, selectedOrg], options)
        // Setting selectedOrgAncestors ultimately recomputes the default filters, which causes this addFilterOptions to be called again.
        // This is a hack to prevent selectedOrgAncestors being set again and infinite "recursion" happening.
        if (selectedOrgAncestors) return ensureOrgOptionsIncluded([...selectedOrgAncestors, fullSelectedOrg], options)
        const { orgId } = selectedOrg
        const orgsPromise =
          ancestorOrgsById[orgId] ??
          (ancestorOrgsById[orgId] = api.org.list(
            {
              pageSize: null,
              offset: 0,
              filters: [
                {
                  type: FilterType.Verification,
                  config: {
                    verified: null, // Include verified and unverified orgs
                  },
                },
                {
                  type: FilterType.HasDescendantOrg,
                  config: {
                    descendantOrgIds: [orgId],
                  },
                },
              ],
            },
            api.noMonitor
          ))
        try {
          const { orgs } = await orgsPromise
          fullSelectedOrg = orgs.find(o => o.orgId === orgId)
          selectedOrgAncestors = getFlatAncestors(fullSelectedOrg, orgs, false)
          return ensureOrgOptionsIncluded([...selectedOrgAncestors, fullSelectedOrg], options)
        } catch {
          // Just bail out and return the options as-is.
          ancestorOrgsById[orgId] = null
          selectedOrgAncestors = []
          return options
        }
      },
    },

    [FilterType.Teams]: {
      addFilterOptions(options) {
        if (options == null) return null
        if (selectedTeam.teamId == null || options.some(o => o.teamId === selectedTeam.teamId)) return options
        return [selectedTeam, ...options]
      },
    },
  }

  let input = {}
  let inputInitial = {}

  let _selectedOrg = null
  let _selectedService = unset
  let _selectedTeam = null
  let hasTeamOptions = false
  let selectedOrgAncestors = null
  let fullSelectedOrg = null
  let selectedService = null
  let serviceDisciplines = null
  let serviceIsNotValid = false
  let serviceSpecialties = null
  let serviceTooltipElem = null

  let allowOverlap = true
  let availableDisciplines = []
  let fromTab = null

  let invalidOpportunityDisciplines = false
  let agreementDisciplines = []
  let agreementsWithoutDisciplines = []
  let agreementsWithoutDisciplinesTooltipElem = null
  let guestsWithoutDisciplines = []
  let guestsWithoutDisciplinesTooltipElem = null
  let removedDisciplines = []
  let removedDisciplinesTooltipElem = null

  let removedSpecialties = []
  let removedSpecialtiesTooltipElem = null

  let setFromCapacity = false
  let tabs
  let usesCustomDisciplines = false

  let hasNeededOrgSettings = false
  let orgSettings = null
  let orgSettingsAreForOrgId = null

  setInput()
  $: if (!saving && !hasChanges && !validator.equals(capacity, inputInitial)) setInput()
  $: allowedPaths = [new RegExp(`${_.escapeRegExp(baseHref)}(?:$|/.+)`)] // so can add/edit/delete schools, and maybe later shifts
  $: hasChanges = unsavedForms.formHasChanges(form, inputInitial, input, { allowedPaths })
  $: selectedOrg = capacityId
    ? {
        orgId: input.orgId,
        ancestorIds: input.orgAncestorIds,
        name: input.orgName,
        relativeName: input.orgRelativeName,
        relativePath: input.orgRelativePath,
        profilePicture: input.orgProfilePicture,
        // Currently not being sent, but not a big deal.
        formattedAddress: input.orgFormattedAddress,
      }
    : _selectedOrg

  $: selectedTeam = capacityId
    ? input.teamId
      ? {
          teamId: input.teamId,
          orgId: input.teamOrgId,
          name: input.teamName,
        }
      : allTeams
    : _selectedTeam ?? allTeams
  $: staffCount = input.staff?.length || 0

  // If they come in on a different tab, selectedService won't be set by the picker
  // but we need to be able to differentiate between unset and null
  $: selectedService = _selectedService === unset ? input.service : _selectedService

  $: selectedService, input.guests, personaDisciplines, getDisciplines()

  $: updateSpecialitiesRadios(selectedService?.specialties), input.guests
  $: updateDisciplinesRadios(availableDisciplines), input.guests

  $: if (capacity && !setFromCapacity) {
    setFromCapacity = true
    updateSpecialitiesRadios(capacity.serviceSpecialties)
    updateDisciplinesRadios(capacity.serviceDisciplines)
  }

  function onRemoveStaff(userId) {
    input.staff = input.staff.filter(s => s.userId !== userId)
  }
  $: if (!usesCustomDisciplines) input.disciplines = []
  $: checkAgreementsHasDisciplines(input.disciplines)
  $: inputOrgId = input.orgId
  $: if (hasNeededOrgSettings && inputOrgId !== orgSettingsAreForOrgId) loadOrgSettings()

  function checkAgreementsHasDisciplines(disciplines) {
    if (invalidOpportunityDisciplines) return
    if (usesCustomDisciplines) {
      const agreements = input.guests?.filter(guest => guest.agreement).map(guest => guest.agreement)
      agreementsWithoutDisciplines = agreements.filter(a => a.disciplines?.length && !a?.disciplines.some(d => disciplines.includes(d)))
      guestsWithoutDisciplines = input.guests?.filter(guest => agreementsWithoutDisciplines.some(a => a.agreementId === guest.agreementId))
    } else {
      agreementsWithoutDisciplines = []
    }
  }

  async function loadOrgSettings() {
    orgSettings = null
    orgSettingsAreForOrgId = null
    const orgId = inputOrgId
    const settings = await api.org.getSettings({ orgId }, api.noMonitor)
    // If the orgId hasn't changed since the request was made, update the orgSettings
    if (orgId !== inputOrgId) return
    orgSettings = settings
    orgSettingsAreForOrgId = inputOrgId
  }

  function updateSpecialitiesRadios(specialties) {
    if (serviceSpecialties?.every(s => specialties?.includes(s))) return
    serviceSpecialties = specialties ?? null
    if (serviceSpecialties?.length) {
      const before = input.specialties
      const upperServiceSpecialties = new Set(serviceSpecialties.map(toUpper))
      input.specialties = input.specialties.filter(s => upperServiceSpecialties.has(toUpper(s)))
      removedSpecialties = before.filter(s => !input.specialties.includes(s))
      fromTab = tabName
    }
  }

  function updateDisciplinesRadios(disciplines) {
    if (disciplines && serviceDisciplines?.length && serviceDisciplines?.every(d => disciplines.includes(d))) return
    serviceDisciplines = disciplines
    if (serviceDisciplines?.length) {
      const before = input.disciplines
      const upperServiceDisciplines = new Set(serviceDisciplines.map(toUpper))
      input.disciplines = input.disciplines.filter(s => upperServiceDisciplines.has(toUpper(s)))
      removedDisciplines = before.filter(s => !input.disciplines.includes(s))
      fromTab = tabName
    }
  }

  $: selectedTeamId = input.teamId
  $: selectedServiceId = input.serviceId
  $: isServiceView = serviceId != null
  $: capacityId = capacity?.capacityId
  $: canScheduleCoreRotations = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: canEditCapacity = personaService.canEditCapacity(capacity)
  // when parent component sends a new capacity in (from a websocket update, for instance) and the user hasn't made any changes yet, update the UI

  $: manageCapacityRoles =
    capacityId == null
      ? null
      : personaService.getRolesWithPermission(Permission.ManageOpportunitiesAndServices, capacity.orgId, capacity.teamId, { capacityId })
  $: personaDisciplines =
    manageCapacityRoles == null || manageCapacityRoles.some(osr => !osr.disciplines?.length)
      ? null
      : _.uniq(manageCapacityRoles.flatMap(osr => osr.disciplines ?? []))
  $: validationMessagesByFormGroupByFormSection = {
    general: {
      name: validator.name(input.name) ? null : 'You must enter a name for the opportunity.',
      scheduling: !input.openForScheduling || input.postToSearchPage || input.allowCoordinatorScheduling ? null : true, // Actual message is in slot content so icons can be used,
    },
    schools: {
      disciplines: invalidOpportunityDisciplines ? '' : input.disciplines?.length ? (agreementsWithoutDisciplines?.length ? '' : null) : null,
    },
  }

  // To prevent chatty/broken reactivity
  $: defaultServiceAndAgreementFilter = [
    {
      type: FilterType.HostOrgs,
      config: {
        orgIds: _.uniq(
          [...(selectedOrg?.ancestorOrgIds ?? []), ...(selectedOrg?.ancestors?.map(o => o.orgId) ?? []), selectedOrg?.orgId].filter(Boolean)
        ),
        exclude: false,
      },
    },
    hasTeamOptions
      ? {
          type: FilterType.Teams,
          config: {
            teamIds: selectedTeamId ? [null, selectedTeamId] : [null],
            exclude: false,
          },
        }
      : null,
  ].filter(Boolean)

  $: if (allowOverlap) input.noNewMatchesForDayWhenOthersHaveBeen = null
  $: if (!input.postToSearchPage) input.allowStudentScheduling = false
  $: if (!input.allowCoordinatorScheduling) {
    input.allowHostCoordinatorScheduling = false
    input.allowGuestCoordinatorScheduling = false
    input.allowStudentChanges = false
  }

  $: defaultServiceFilter = [...defaultServiceAndAgreementFilter].filter(Boolean)

  $: allowedServiceIds, setValidServiceIdIfNotSetValidAlready()
  $: serviceIsNotValid = allowedServiceIds?.length > 0 && !allowedServiceIds.includes(selectedServiceId)
  $: changedOrNotOpenForScheduling = input.openForScheduling !== inputInitial.openForScheduling || !input.capacityId

  function onInvalidSubmit(e) {
    const invalidTabs = e.detail
    tabName = invalidTabs[0]
  }

  function setInput() {
    input = _.cloneDeep(capacity)
    input.orgId ??= $personaFilters.orgId
    input.teamId ??= $personaFilters.teamId ?? null
    input.startDate = dateService.datestamp(input.startDate)
    input.endDate = dateService.datestamp(input.endDate)
    input.matchLeadTimeDays = input.matchLeadTimeDays ?? 0
    input.shifts ??= []
    input.locationIds ??= []
    input.rotationTypes ??= []
    input.specialties ??= []
    input.disciplines ??= []
    input.guests ??= []
    input.staff ??= []
    input.serviceId ??= serviceId
    input.studentYears ??= []
    input.dateExceptions ??= []
    input.requestAvailabilityPolicy ??= RequestAvailabilityPolicy.WindowsAndOpenTimes
    input.postToSearchPage ??= !input.allowGroups
    input.allowCoordinatorScheduling ??= input.allowGroups
    input.allowStudentChanges ??= input.allowGroups
    input.allowStudentChanges &&= input.allowCoordinatorScheduling
    input.allowHostCoordinatorScheduling ??= input.allowGroups
    input.allowGuestCoordinatorScheduling ??= input.allowGroups
    input.allowStudentsToLeaveGroupMatch = input.allowStudentsToLeaveGroupMatch === null ? input.allowGroups : !input.allowStudentsToLeaveGroupMatch
    inputInitial = _.cloneDeep(input)

    allowOverlap = input.noNewMatchesForDayWhenOthersHaveBeen == null
    usesCustomDisciplines = !!input.disciplines.length
  }

  function submit() {
    const matchCount = _.sumBy(capacity?.guests, 'matchCount')
    // TODO: show a breakdown of what these rotations are. could at least show capacity.statusCounts
    if (matchCount > 0)
      confirms.add({
        title: 'Update opportunity',
        message: `This opportunity is in use by ${pluralCount('rotation', matchCount)}. Are you sure you’d like to update it?`,
        confirmLabel: 'Update',
        confirmClass: 'btn-warning',
        onConfirm: () => finishSubmit(),
      })
    else finishSubmit()
  }

  async function finishSubmit() {
    if (capacity) input.capacityId = capacity.capacityId
    const wasSuccessful = await saveCapacity(input)
    if (!wasSuccessful) return
    inputInitial = _.cloneDeep(input)
    unsavedForms.del(form)
    onAfterCapacitySaved(input.capacityId)
  }

  function onDefaultOrgId(orgId) {
    inputInitial.orgId ??= orgId
  }

  function onDefaultTeamId(teamId) {
    inputInitial.teamId ??= teamId
  }

  function setValidServiceIdIfNotSetValidAlready() {
    if (allowedServiceIds == null || allowedServiceIds.length === 0 || allowedServiceIds.includes(serviceId)) return
    input.serviceId = allowedServiceIds[0]
  }

  function ensureOrgOptionsIncluded(orgsToInclude, options) {
    const optionsToAdd = []
    for (const org of orgsToInclude) {
      if (!options.some(o => o.orgId === org.orgId)) optionsToAdd.push(org)
    }
    return [...optionsToAdd, ...options]
  }

  function getDisciplines() {
    invalidOpportunityDisciplines = false
    const agreements = input.guests.map(g => g.agreement)
    const serviceDisciplines = selectedService?.disciplines
    const filteredServiceDisciplines = serviceDisciplines?.length
      ? personaDisciplines?.length
        ? _.intersection(serviceDisciplines, personaDisciplines)
        : serviceDisciplines
      : personaDisciplines

    const guestsDisciplines = _.uniq(agreements.flatMap(a => a?.disciplines || []))
    agreementDisciplines = guestsDisciplines.length
      ? personaDisciplines?.length
        ? _.intersection(guestsDisciplines, personaDisciplines)
        : guestsDisciplines
      : []

    if (filteredServiceDisciplines?.length) {
      availableDisciplines = agreementDisciplines.length
        ? filteredServiceDisciplines.filter(discipline => agreementDisciplines.includes(discipline))
        : filteredServiceDisciplines
    } else {
      availableDisciplines = agreementDisciplines
    }
    if (!availableDisciplines.length && filteredServiceDisciplines?.length && agreementDisciplines.length) {
      invalidOpportunityDisciplines = true
      agreementsWithoutDisciplines = agreements
    }
  }
</script>

<style lang="scss">
  @import '../../css/helpers';
  .visibility-settings {
    width: 400px;
    border: 3px solid #f7f6f6;
    background-color: #f7f6f6;
  }
</style>
