{#if initFailure}
  <Alert type="danger" class="mx2">
    Map failed to load. Your browser might not support WebGL. If you’re sure it does, try restarting your browser.
  </Alert>
{/if}

<div bind:this={container} class="map-container" />

{#if map}
  <slot {map} {mapbox} />
{/if}

<script context="module">
  // if this changes, update MapBounds.cs.WithinBoundsSql
  export function withinBounds(latLong, bounds) {
    const { lat, long } = latLong
    const isCluster = bounds.south !== bounds.north // if we had bounds, we'd have a diff between north/south. Else, we'd have default 0s for all 4 corners.
    return isCluster
      ? lat >= bounds.south && lat < bounds.north && long >= bounds.west && long < bounds.east
      : bounds.lat === lat && bounds.long === long
  }
</script>

<script>
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import Alert from 'components/bootstrap/Alert.svelte'
  import LocalStorageStore from 'stores/local-storage-store.js'
  import mapbox from 'mapbox-gl'

  export let tokenKey
  // bounds { north, south, east, west } (lat for north/south, lng for east/west)
  export let bounds = null
  export let center = null
  export let zoom = null
  export let fitToLongLats = null
  export let map

  const style = 'mapbox://styles/cnprimary/ck8s0v67q1pyh1io447zcvwz3'
  const tokens = {
    addressmap: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwbGc2dTAzZHkzZXFweHRncWFzMG0ifQ.Eing-SCh9pkqJPOZ-UjpBQ',
    orgpicker: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwbHk4NjAyZTMzbXJ1cDdmOWhqMDAifQ.KN3CHiNxLLOrrBa_mnClEg',
    profilesearch: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwazFnaTAwdWYzbnBtcTFsaHV5bmsifQ.TRh6uVCrKxCMngPjksf4NQ',
    rotationsearch: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwa2t6MzAwMW8za3FqZXg2OGt6MHkifQ.ZIifr0P_ez8UXsC0JNVPnA',
    orgs: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwaTJyejAzeGIzbXBhMnNzejY4N3AifQ.73QkpYebbRu1KqYbQ7iQPw',
  }
  const Minneapolis = {
    long: -93.2650108,
    lat: 44.977753,
  }

  // if explicit center passed, use that, otherwise load from local storage
  const centerStore = center == null ? LocalStorageStore('map-center', [Minneapolis.long, Minneapolis.lat]) : writable(center)

  // if explicit zoom passed, use that, otherwise load from local storage
  const zoomStore = zoom == null ? LocalStorageStore('map-zoom', 10) : writable(zoom)

  let container
  let initFailure = false
  let observer = null
  let containerWidth = null
  let containerHeight = null

  onMount(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css'
    link.addEventListener('load', () => initMap())
    document.head.append(link)

    return () => {
      map?.remove()
      link?.remove()
    }
  })

  $: if (fitToLongLats?.length && map) {
    const newBounds = new mapbox.LngLatBounds()
    for (const marker of fitToLongLats) newBounds.extend([marker.long, marker.lat])
    fitBounds(buildBounds(newBounds), 1.5)
  }

  $: container, map, initResizeObserver()

  function initResizeObserver() {
    if (container == null || map == null || observer != null) return
    containerWidth = container.offsetWidth
    containerHeight = container.offsetHeight
    observer = new ResizeObserver(() => {
      if (container == null) return
      const newWidth = container.offsetWidth
      const newHeight = container.offsetHeight
      if (containerWidth !== newWidth || containerHeight !== newHeight) {
        // If we just call map.resize() here, while resizing the browser, the map goes completely
        // blank (transparent or white) and depending on how fast you're resizing, it flickers in and out.
        // However, if you set a timeout for 0ms, it renders flawlessly. Also hilarious: requestAnimationFrame
        // behaves just the same as calling map.resize() directly.
        setTimeout(() => {
          try {
            map.resize()
          } catch {
            // Map or its canvas may have been destroyed.
          }
        }, 0)
      }
      containerWidth = newWidth
      containerHeight = newHeight
    })
    observer.observe(container)
  }

  export function flyTo(coords, options) {
    $centerStore = [coords.long, coords.lat]
    return map?.flyTo({
      center: $centerStore,
      essential: true,
      // maxDuration: 500, // doesn't seem to do anything...
      ...options,
    })
  }

  export function fitBounds(bounds, lngLatOffset = 0.5, options = {}) {
    /*
      If we fit to the bounds the server says with 0 padding using Mapbox's fitBoundsOptions.padding,
      Mapbox may not entirely fit the whole bounds. This is a problem because the next web request will
      use Mapbox's bounds to get the data, but it won't come back with the results we were expecting because
      the bounds have shrunk in some dimension.
      For example, this is what I got from the server, and then what the next request we fired used:
        east:  150.156703
               150.1567030000012      We're slightly more east than the server's bounds, which is fine.
        north:  46.3366
                52.57170679036807     We're way more north than the server's bounds, also fine.
        south: -33.481283
               -41.113329251609144    We're way more south than the server's bounds, also fine.
        west:  -94.6462
               -94.64619999999928     We're less west than the server's bounds, which is a problem. This will exclude results.
      Even setting the padding to 40 doesn't always work, so let's just adjust the bounds to be a little bigger.
      But let's also use their padding option to ensure we at least have an uncramped amount of
      padding around the bounds, since depending on the bounds, 0.5 degrees might be pretty small.
    */
    const mapboxLatLongBounds = [
      [bounds.west - lngLatOffset, bounds.south - lngLatOffset],
      [bounds.east + lngLatOffset, bounds.north + lngLatOffset],
    ]

    return map?.fitBounds(mapboxLatLongBounds, { padding: 24, ...options })
  }

  export function zoomToCluster(cluster) {
    // Fly to the the cluster marker, but put it at the center of the size of the cluster's bounds
    // (server uses avg lat/long, so the point is not necessarily in the center of the cluster bounds)
    const nsDiff = Math.abs(cluster.north - cluster.south) / 2
    const ewDiff = Math.abs(cluster.east - cluster.west) / 2
    const bounds = {
      north: cluster.lat + nsDiff,
      south: cluster.lat - nsDiff,
      east: cluster.long + ewDiff,
      west: cluster.long - ewDiff,
    }
    fitBounds(bounds)
  }

  export function getApi() {
    return { map, mapbox }
  }

  function buildBounds(mapboxBounds) {
    const sw = mapboxBounds.getSouthWest()
    const ne = mapboxBounds.getNorthEast()
    return {
      north: ne.lat,
      south: sw.lat,
      east: ne.lng,
      west: sw.lng,
    }
  }

  function getBounds() {
    return buildBounds(map.getBounds())
  }

  function initMap() {
    if (container == null) return // component probably was destroyed in between component mount and map box css load
    mapbox.accessToken = tokens[tokenKey]
    try {
      map = new mapbox.Map({
        container,
        style,
        center: $centerStore,
        zoom: $zoomStore,
        // Mapbox's resize tracking doesn't catch the initial resize, so we'll just handle all
        // resize events ourselves. The problem with resizing is the bounds change; before
        // we were unconditionally calling map.resize() in the load event, but that causes
        // tiny changes to the bounds, which is difficult to debounce an API request.
        trackResize: false,
      })

      // Rotating the map isn't super useful and we have logic in place to prevent
      // map markers for overlapping that assume the map is not rotated.
      // So, let's disable it.
      map.dragRotate.disable()
      map.addControl(new mapbox.NavigationControl())
      map.addControl(
        new mapbox.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        })
      )

      bounds = getBounds()
      map.on('move', () => {
        bounds = getBounds()
        // store for page reload
        $centerStore = map.getCenter()
        $zoomStore = map.getZoom()
      })

      // attempt to get geolocation and move to user's location if they're on the default location
      if (navigator.geolocation && $centerStore[0] == Minneapolis.long && $centerStore[1] == Minneapolis.lat && window.Cypress == null) {
        navigator.geolocation.getCurrentPosition(
          position => {
            map?.flyTo({
              center: [position.coords.longitude, position.coords.latitude],
              essential: true,
              maxDuration: 500,
            })
          },
          () => {
            // don't care
          }
        )
      }
    } catch (error) {
      // if fail to load map, set bounds to fully-zoomed out, so calling code sends the full earth's bounds and gets all possible results if they're two-way bound to `bounds`
      const earth = {
        north: 85.05112899999989,
        south: -85.05112899999997,
        east: 195.89977880888483,
        west: -454.39635797521333,
      }
      bounds = earth

      initFailure = true
      throw error
    }
  }
</script>

<style lang="scss">
  .map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
</style>
