import {
  faAppStoreIos as brandAppStoreIos,
  faCodepen as brandCodepen,
  faFacebookF as brandFacebookF,
  faGooglePlay as brandGooglePlay,
  faLinkedinIn as brandLinkedinIn,
  faMarkdown as brandMarkdown,
  faSlack as brandSlack,
  faTwitter as brandTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard as duoAddressCard,
  faArrowsRepeat as duoArrowsRepeat,
  faBarsProgress as duoBarsProgress,
  faBells as duoBells,
  faBlinds as duoBlinds,
  faBolt as duoBolt,
  faBomb as duoBomb,
  faBook as duoBook,
  faBooks as duoBooks,
  faBoxOpen as duoBoxOpen,
  faBriefcaseMedical as duoBriefcaseMedical,
  faCalendarAlt as duoCalendarAlt,
  faCalendarArrowUp as duoCalendarArrowUp,
  faCalendarCheck as duoCalendarCheck,
  faCalendarClock as duoCalendarClock,
  faCalendarDay as duoCalendarDay,
  faCalendarLinesPen as duoCalendarLinesPen,
  faCalendarPlus as duoCalendarPlus,
  faCalendars as duoCalendars,
  faCalendarXmark as duoCalendarXmark,
  faChartBar as duoChartBar,
  faChartPieAlt as duoChartPieAlt,
  faClipboardList as duoClipboardList,
  faClipboardListCheck as duoClipboardListCheck,
  faClock as duoClock,
  faCloudArrowUp as duoCloudArrowUp,
  faCogs as duoCogs,
  faCommentsQuestion as duoCommentsQuestion,
  faCommentsQuestionCheck as duoCommentsQuestionCheck,
  faCopy as duoCopy,
  faCube as duoCube,
  faCubes as duoCubes,
  faDesktop as duoDesktop,
  faDuck as duoDuck,
  faEdit as duoEdit,
  faEye as duoEye,
  faEyeLowVision as duoEyeLowVision,
  faFile as duoFile,
  faFileCertificate as duoFileCertificate,
  faFileCircleInfo as duoFileCircleInfo,
  faFileContract as duoFileContract,
  faFiles as duoFiles,
  faFileSpreadsheet as duoFileSpreadsheet,
  faFilters as duoFilters,
  faFingerprint as duoFingerprint,
  faGear as duoGear,
  faGraduationCap as duoGraduationCap,
  faHistory as duoHistory,
  faHospitalUser as duoHospitalUser,
  faIdBadge as duoIdBadge,
  faIdCard as duoIdCard,
  faInputText as duoInputText,
  faLeaf as duoLeaf,
  faLineColumns as duoLineColumns,
  faLink as duoLink,
  faList as duoList,
  faListCheck as duoListCheck,
  faListOl as duoListOl,
  faListRadio as duoListRadio,
  faListTree as duoListTree,
  faLock as duoLock,
  faMemoCircleInfo as duoMemoCircleInfo,
  faNotebook as duoNotebook,
  faPaperPlane as duoPaperPlane,
  faRoadBarrier as duoRoadBarrier,
  faRocket as duoRocket,
  faScroll as duoScroll,
  faShapes as duoShapes,
  faShareAlt as duoShareAlt,
  faShoppingCart as duoShoppingCart,
  faSignature as duoSignature,
  faSignOut as duoSignOut,
  faSitemap as duoSitemap,
  faSlidersSimple as duoSlidersSimple,
  faSpinnerThird as duoSpinnerThird,
  faSquareEllipsisVertical as duoSquareEllipsisVertical,
  faSquareExclamation as duoSquareExclamation,
  faSquareKanban as duoSquareKanban,
  faStamp as duoStamp,
  faStarChristmas as duoStarChristmas,
  faStars as duoStars,
  faTasks as duoTasks,
  faTools as duoTools,
  faTriangleExclamation as duoTriangleExclamation,
  faUserClock as duoUserClock,
  faUserCog as duoUserCog,
  faUserRobotXmarks as duoUserRobotXmarks,
  faUsers as duoUsers,
  faUsersCog as duoUsersCog,
  faUserTie as duoUserTie,
  faUserXmark as duoUserXmark,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faAnalytics as solidAnalytics,
  faAngleDoubleLeft as solidAngleDoubleLeft,
  faAngleDoubleRight as solidAngleDoubleRight,
  faAngleLeft as solidAngleLeft,
  faAngleRight as solidAngleRight,
  faArrowLeft as solidArrowLeft,
  faArrowsSpin as solidArrowsSpin,
  faAsterisk as solidAsterisk,
  faAt as solidAt,
  faBell as solidBell,
  faBlockQuestion as solidBlockQuestion,
  faBolt as solidBolt,
  faBoxArchive as solidBoxArchive,
  faBracketsCurly as solidBracketsCurly,
  faBrainCircuit as solidBrainCircuit,
  faBuilding as solidBuilding,
  faCalendarAlt as solidCalendarAlt,
  faCamera as solidCamera,
  faCaretDown as solidCaretDown,
  faCaretLeft as solidCaretLeft,
  faCaretRight as solidCaretRight,
  faCaretUp as solidCaretUp,
  faChartBar as solidChartBar,
  faChartNetwork as solidChartNetwork,
  faChartPie as solidChartPie,
  faCheckCircle as solidCheckCircle,
  faCheckDouble as solidCheckDouble,
  faCheckSquare as solidCheckSquare,
  faChevronDoubleLeft as solidChevronDoubleLeft,
  faChevronDown as solidChevronDown,
  faChevronLeft as solidChevronLeft,
  faChevronRight as solidChevronRight,
  faChevronUp as solidChevronUp,
  faCircle as solidCircle,
  faCircleArrowLeft as solidCircleArrowLeft,
  faClipboardCheck as solidClipboardCheck,
  faClock as solidClock,
  faCog as solidCog,
  faCoinVertical as solidCoinVertical,
  faColumns as solidColumns,
  faComment as solidComment,
  faCommentAltLines as solidCommentAltLines,
  faCommentCode as solidCommentCode,
  faCommentQuestion as solidCommentQuestion,
  faCommentXmark as solidCommentXmark,
  faDatabase as solidDatabase,
  faDesktop as solidDesktop,
  faDollarSign as solidDollarSign,
  faDotCircle as solidDotCircle,
  faDownload as solidDownload,
  faDuck as solidDuck,
  faEllipsisV as solidEllipsisV,
  faEnvelope as solidEnvelope,
  faExchangeAlt as solidExchangeAlt,
  faExclamation as solidExclamation,
  faExclamationCircle as solidExclamationCircle,
  faExclamationTriangle as solidExclamationTriangle,
  faExpand as solidExpand,
  faExternalLinkAlt as solidExternalLinkAlt,
  faEyeSlash as solidEyeSlash,
  faFighterJet as solidFighterJet,
  faFileImage as solidFileImage,
  faFileImport as solidFileImport,
  faFilePdf as solidFilePdf,
  faFileText as solidFileText,
  faFileWord as solidFileWord,
  faFilter as solidFilter,
  faFingerprint as solidFingerprint,
  faFloppyDiskCircleArrowRight as solidSaveArrowRight,
  faGearCode as solidGearCode,
  faGlobe as solidGlobe,
  faGraduationCap as solidGraduationCap,
  faGripLinesVertical as solidGripLinesVertical,
  faHomeAlt as solidHomeAlt,
  faHospital as solidHospital,
  faHourglassHalf as solidHourglassHalf,
  faHouseLaptop as solidHouseLaptop,
  faHouseLeave as solidHouseLeave,
  faHouseReturn as solidHouseReturn,
  faIdBadge as solidIdBadge,
  faImage as solidImage,
  faInfinity as solidFaInfinity,
  faInfoCircle as solidInfoCircle,
  faKey as solidKey,
  faLightbulb as solidLightbulb,
  faLockOpen as solidLockOpen,
  faLongArrowRight as solidLongArrowRight,
  faMap as solidMap,
  faMapMarkerAlt as solidMapMarkerAlt,
  faMask as solidMask,
  faMinusSquare as solidMinusSquare,
  faPaperclip as solidPaperclip,
  faParagraph as solidParagraph,
  faPauseCircle as solidPauseCircle,
  faPercent as solidPercent,
  faPhone as solidPhone,
  faPig as solidPig,
  faPlay as solidPlay,
  faPrint as solidPrint,
  faQuestionCircle as solidQuestionCircle,
  faQuoteLeft as solidQuoteLeft,
  faRedo as solidRedo,
  faRocket as solidRocket,
  faSave as solidSave,
  faSchool as solidSchool,
  faSearch as solidSearch,
  faServer as solidServer,
  faShieldCheck as solidShieldCheck,
  faSigma as solidSigma,
  faSort as solidSort,
  faSquare as solidSquare,
  faSquareCaretDown as duoListDropdown,
  faStar as solidStar,
  faStop as solidStop,
  faTable as solidTable,
  faTag as solidTag,
  faTasks as solidTasks,
  faThumbsDown as solidThumbsDown,
  faThumbsUp as solidThumbsUp,
  faThumbTack as solidThumbtack,
  faTimer as solidTimer,
  faTimesCircle as solidTimesCircle,
  faTrashAlt as solidTrashAlt,
  faTrashUndo as solidTrashUndo,
  faUndo as solidUndo,
  faUnlock as solidUnlock,
  faUpload as solidUpload,
  faUser as solidUser,
  faUserCheck as solidUserCheck,
  faUserGraduate as solidUserGraduate,
  faUserPlus as solidUserPlus,
  faUserRobot as solidUserRobot,
  faUserShield as solidUserShield,
  faWavePulse as solidWavePulse,
  faWeightHanging as solidWeightHanging,
  faWrench as solidWrench,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faBan as regularBan,
  faBarsProgress as regularBarsProgress,
  faBell as regularBell,
  faChartNetwork as regularChartNetwork,
  faCheck as regularCheck,
  faCheckDouble as regularCheckDouble,
  faCirclesOverlap as regularCirclesOverlap,
  faClock as regularClock,
  faClone as regularClone,
  faCode as regularCode,
  faCodeMerge as regularCodeMerge,
  faCodePullRequest as regularCodePullRequest,
  faComment as regularComment,
  faGreaterThanEqual as regularGreaterThanEqual,
  faHashtag as regularHashtag,
  faHeartCircleCheck as regularHeartCircleCheck,
  faMinus as regularMinus,
  faPlus as regularPlus,
  faSortAlphaDown as regularSortAlphaDown,
  faSortAlphaDownAlt as regularSortAlphaDownAlt,
  faSortAmountDown as regularSortAmountDown,
  faSortAmountDownAlt as regularSortAmountDownAlt,
  faSortNumericDown as regularSortNumericDown,
  faSortNumericDownAlt as regularSortNumericDownAlt,
  faSortShapesDown as regularSortShapesDown,
  faSortShapesDownAlt as regularSortShapesDownAlt,
  faSortSizeDown as regularSortSizeDown,
  faSortSizeDownAlt as regularSortSizeDownAlt,
  faSquare as regularSquare,
  faStar as regularStar,
  faStopwatch as regularStopwatch,
  faSyncAlt as regularSyncAlt,
  faThumbTack as regularThumbtack,
  faTimes as regularTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { faChalkboardUser as sharpSolidChalkboardUser, faGameBoardSimple as sharpSolidGameBoard } from '@fortawesome/sharp-solid-svg-icons'

import buildIcon from 'config/build-icon.js'
import { faBars as lightBars } from '@fortawesome/pro-light-svg-icons'
import rotation from 'config/rotation-icon.js'
import { faStar as thinStar } from '@fortawesome/pro-thin-svg-icons'

const cnDuoSearchSuccess = buildIcon('search-success', 512, 512, [
  'm300.36,141.51c-3.97-3.97-9.22-6.12-14.81-6.12h-.03c-5.59,0-10.84,2.19-14.75,6.12l-88.57,88.52-36.91-36.87c-8.13-8.12-21.38-8.19-29.63.03-8.13,8.16-8.09,21.43.03,29.56l51.69,51.68c4.06,4.06,9.41,6.09,14.78,6.09s10.72-2.03,14.81-6.09l103.35-103.33c8.16-8.16,8.16-21.43,0-29.59h.03Z',
  'm376.02,330.65c25.1-34.4,40-76.79,40-122.68C416.02,93.09,322.92,0,208.01,0S0,93.09,0,207.97s93.1,207.97,208.01,207.97c45.9,0,88.31-14.8,122.71-39.99l126.61,126.68c12.5,12.5,32.8,12.5,45.3,0,12.5-12.5,12.5-32.79,0-45.29l-126.61-126.68Zm-168.01,21.3c-79.53,0-144.01-64.46-144.01-143.98S128.48,63.99,208.01,63.99s144.01,64.46,144.01,143.98-64.47,143.98-144.01,143.98Z',
])

const cnDuoSearchFail = buildIcon('search-fail', 512, 512, [
  'm306.42,131.09c0-5.75-2.25-11.14-6.34-15.17-8.47-8.39-22.14-8.41-30.44.03l-61.57,61.55-61.64-61.62c-4.08-4.08-9.5-6.33-15.25-6.33h-.06c-5.75.02-11.14,2.28-15.17,6.36-8.38,8.45-8.36,22.12.03,30.43l61.57,61.55-61.63,61.63c-4.09,4.09-6.34,9.53-6.33,15.31.02,5.73,2.28,11.14,6.36,15.17,8.5,8.41,22.14,8.31,30.44-.03l61.57-61.55,61.64,61.62c4.08,4.09,9.5,6.34,15.25,6.31h.06c5.73,0,11.14-2.25,15.17-6.34,8.38-8.47,8.34-22.14-.03-30.43l-61.57-61.55,61.63-61.63c4.09-4.09,6.34-9.53,6.31-15.31Z',
  'm502.62,457.33l-126.61-126.68c25.1-34.39,40-76.79,40-122.68C416.02,93.09,322.92,0,208.01,0S0,93.09,0,207.97s93.1,207.97,208.01,207.97c45.9,0,88.31-14.8,122.71-39.99l126.61,126.68c12.5,12.5,32.8,12.5,45.3,0,12.5-12.5,12.5-32.79,0-45.29Zm-294.61-105.39c-79.53,0-144.01-64.46-144.01-143.98S128.48,63.99,208.01,63.99s144.01,64.46,144.01,143.98-64.47,143.98-144.01,143.98Z',
])

const cnDuoSlots = buildIcon('slots', 608, 512, [
  'm 128,416 c 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 z M 64,400 c -8.84,0 -16,7.2 -16,16 0,8.8 7.16,16 16,16 8.84,0 16,-7.2 16,-16 0,-8.8 -7.16,-16 -16,-16 z m 544,16 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m -96,16 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z M 608,256 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z M 288,416 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z',
  'M 0,96 C 0,60.65 28.65,32 64,32 c 35.35,0 64,28.65 64,64 0,35.3 -28.65,64 -64,64 C 28.65,160 0,131.3 0,96 Z m 320,160 c 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 z m -160,0 c 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 z M 0,256 c 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 0,35.3 -28.65,64 -64,64 C 28.65,320 0,291.3 0,256 Z M 608,95.999997 c 0,35.300013 -28.7,64.000013 -64,64.000013 -35.3,0 -64,-28.7 -64,-64.000013 C 480,60.649997 508.7,32 544,32 c 35.3,0 64,28.649997 64,63.999997 z m -160,0 c 0,35.300013 -28.7,64.000013 -64,64.000013 -35.3,0 -64,-28.7 -64,-64.000013 C 320,60.649997 348.7,32 384,32 c 35.3,0 64,28.649997 64,63.999997 z M 288,96 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.35 28.7,-64 64,-64 35.3,0 64,28.65 64,64 z',
])

const cnDuoSlotsRelease = buildIcon('slots-release', 456, 512, [
  //'m 128,256 c 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 z M 64,240 c -8.84,0 -16,7.2 -16,16 0,8.8 7.16,16 16,16 8.84,0 16,-7.2 16,-16 0,-8.8 -7.16,-16 -16,-16 z M 288,96 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z M 224,80 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m 64,336 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z',
  // This path has the dots like so:
  //     O
  //   O -->
  //     O
  // But we're gonna use this one instead:
  'm 128,256 c 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 z M 64,240 c -8.84,0 -16,7.2 -16,16 0,8.8 7.16,16 16,16 8.84,0 16,-7.2 16,-16 0,-8.8 -7.16,-16 -16,-16 z M 128,96 c 0,35.3 -28.7,64 -64,64 C 28.7,160 0,131.3 0,96 0,60.7 28.7,32 64,32 c 35.3,0 64,28.7 64,64 z M 64,80 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m 224,16 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z M 224,80 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m -96,336 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z M 64,400 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m 224,16 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z',
  // which has the dots like so:
  //   O O
  //   O -->
  //   O O
  // This is the arrow path:
  'm 338.358,330.04583 a 18.36,18.36 0 0 1 6,-13.64 l 45.68,-42.15999 h -182.04 a 18.6,18.6 0 1 1 0,-37.2 h 181.96 l -45.6,-42.12 a 18.36,18.36 0 0 1 -5.92,-13.68 18.6,18.6 0 0 1 31.24,-13.64 l 80.68001,74.4 a 18.84,18.84 0 0 1 5.64,13.64 18.52,18.52 0 0 1 -5.72,13.64 L 369.598,343.68583 a 18.12,18.12 0 0 1 -31.24,-13.64 z',
])

const cnDuoSlotsStop = buildIcon('slots-stop', 456, 512, [
  // This is the dots path:
  'm 128,256 c 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 z M 64,240 c -8.84,0 -16,7.2 -16,16 0,8.8 7.16,16 16,16 8.84,0 16,-7.2 16,-16 0,-8.8 -7.16,-16 -16,-16 z M 128,96 c 0,35.3 -28.7,64 -64,64 C 28.7,160 0,131.3 0,96 0,60.7 28.7,32 64,32 c 35.3,0 64,28.7 64,64 z M 64,80 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m 224,16 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z M 224,80 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z m -96,336 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z M 64,400 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z',
  // This is the octagon path:
  'm 160,387.5 v -111 L 252.5,184 h 111 l 92.5,92.5 v 111 L 363.5,480 h -111 z',
])

const cnTrioSlotsBase = [
  'M 0,96 C 0,60.65 28.65,32 64,32 c 35.35,0 64,28.65 64,64 0,35.3 -28.65,64 -64,64 C 28.65,160 0,131.3 0,96 Z m 160,160 c 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 z M 0,256 c 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 0,35.3 -28.65,64 -64,64 C 28.65,320 0,291.3 0,256 Z M 608,95.999997 c 0,35.300013 -28.7,64.000013 -64,64.000013 -35.3,0 -64,-28.7 -64,-64.000013 C 480,60.649997 508.7,32 544,32 c 35.3,0 64,28.649997 64,63.999997 z m -160,0 c 0,35.300013 -28.7,64.000013 -64,64.000013 -35.3,0 -64,-28.7 -64,-64.000013 C 320,60.649997 348.7,32 384,32 c 35.3,0 64,28.649997 64,63.999997 z M 288,96 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.35 28.7,-64 64,-64 35.3,0 64,28.65 64,64 z',
  'm 128,416 c 0,35.3 -28.65,64 -64,64 -35.35,0 -64,-28.7 -64,-64 0,-35.3 28.65,-64 64,-64 35.35,0 64,28.7 64,64 z M 64,400 c -8.84,0 -16,7.2 -16,16 0,8.8 7.16,16 16,16 8.84,0 16,-7.2 16,-16 0,-8.8 -7.16,-16 -16,-16 z m 224,16 c 0,35.3 -28.7,64 -64,64 -35.3,0 -64,-28.7 -64,-64 0,-35.3 28.7,-64 64,-64 35.3,0 64,28.7 64,64 z m -64,-16 c -8.8,0 -16,7.2 -16,16 0,8.8 7.2,16 16,16 8.8,0 16,-7.2 16,-16 0,-8.8 -7.2,-16 -16,-16 z',
]

const cnTrioSlotsCheck = buildIcon('slots-check', 608, 512, [
  ...cnTrioSlotsBase,
  'm 300,326 c 0,-85.06094 68.93906,-154 154,-154 85.06094,0 154,68.93906 154,154 0,85.06094 -68.93906,154 -154,154 -85.06094,0 -154,-68.93906 -154,-154 z m 223.66094,-26.58906 c 6.55703,-6.55703 6.55703,-17.26485 0,-23.82188 -6.55703,-6.55703 -17.26485,-6.55703 -23.82188,0 L 434.75,340.67812 408.16094,314.08906 c -6.55703,-6.55703 -17.26485,-6.55703 -23.82188,0 -6.55703,6.55703 -6.55703,17.26485 0,23.82188 l 38.5,38.5 c 6.55703,6.55703 17.26485,6.55703 23.82188,0 z',
])

const cnTrioSlotsQuestion = buildIcon('slots-question', 608, 512, [
  ...cnTrioSlotsBase,
  'm 454,172 c -85.06094,0 -154,68.93906 -154,154 0,85.06094 68.93906,154 154,154 85.06094,0 154,-68.93906 154,-154 0,-85.06094 -68.93906,-154 -154,-154 z m 0,240.625 c -10.82813,0 -19.25,-8.42188 -19.25,-19.25 0,-10.82813 7.88047,-19.25 19.25,-19.25 10.28672,0 19.25,8.42187 19.25,19.25 0,10.82812 -8.96328,19.25 -19.25,19.25 z m 41.56797,-85.42188 -27.13047,16.84375 V 345.25 c 0,7.82031 -6.61719,14.4375 -14.4375,14.4375 -7.82031,0 -14.4375,-6.61719 -14.4375,-14.4375 v -9.625 c 0,-4.8125 2.40625,-9.625 7.21875,-12.63281 l 34.28906,-20.45313 c 4.21094,-2.40625 6.61719,-6.61719 6.61719,-11.42969 0,-7.21875 -6.55703,-13.23437 -13.77578,-13.23437 h -30.73985 c -7.76015,0 -13.23437,6.01562 -13.23437,13.23437 0,7.82032 -6.61719,14.4375 -14.4375,14.4375 -7.82031,0 -14.4375,-6.61718 -14.4375,-14.4375 C 401.0625,267.64844 419.71094,249 442.63047,249 h 30.73984 c 24.54375,0 43.19219,18.64844 43.19219,42.10937 0,14.4375 -7.82031,28.27344 -20.99453,36.09375 z',
])

const cnTrioSlotsExclamation = buildIcon('slots-exclamation', 608, 512, [
  ...cnTrioSlotsBase,
  'm 454,172 c -85.06094,0 -154,68.93906 -154,154 0,85.06094 68.93906,154 154,154 85.06094,0 154,-68.93906 154,-154 0,-85.06094 -68.93906,-154 -154,-154 z m -14.4375,91.4375 C 439.5625,255.49688 446.05937,249 454,249 c 7.94062,0 14.4375,6.4668 14.4375,14.4375 v 77 c 0,7.9707 -6.4668,14.4375 -14.4375,14.4375 -7.9707,0 -14.4375,-6.43672 -14.4375,-14.4375 z M 454,412.625 c -10.44313,0 -18.91313,-8.47 -18.91313,-18.91313 0,-10.44312 8.46399,-18.91312 18.91313,-18.91312 10.44914,0 18.91313,8.47 18.91313,18.91312 C 472.88906,404.14297 464.46719,412.625 454,412.625 Z',
])

const matchStatuses = {
  Active: solidPlay,
  Closed: solidTimesCircle,
  Completed: solidClipboardCheck,
  Evaluation: solidChartBar,
  Offboarding: solidHouseLeave,
  Onboarding: solidHouseReturn,
  Paused: solidPauseCircle,
  PendingApplicationVerification: duoTasks,
  PendingClinicalSite: solidClock,
  Ready: regularCheckDouble,
  Unsubmitted: duoEdit,
  Waitlisted: regularStopwatch,
}

const stepInputTypes = {
  ChecklistItem: regularCheck,
  FileUpload: solidUpload,
  Form: duoEdit,
  PreCheck: solidUserShield,
  CustomIntegration: solidGearCode,
}

const stepTypes = {
  SchoolCompliance: duoNotebook,
  Application: duoTasks,
  // Onboarding and Offboarding are status names too, don't need to redefine
}

const quotedKeys = {
  'alert-circle': solidExclamationCircle,
  'alert-triangle': solidExclamationTriangle,
  'angle-double-left': solidAngleDoubleLeft,
  'angle-double-right': solidAngleDoubleRight,
  'angle-left': solidAngleLeft,
  'angle-right': solidAngleRight,
  'appstore-ios': brandAppStoreIos,
  'arrow-right': solidLongArrowRight,
  'arrows-spin': solidArrowsSpin,
  'bars-progress': duoBarsProgress,
  'block-question': solidBlockQuestion,
  'brackets-curly': solidBracketsCurly,
  'brain-circuit': solidBrainCircuit,
  'briefcase-medical': duoBriefcaseMedical,
  'calendar-check': duoCalendarCheck,
  'calendar-clock': duoCalendarClock,
  'calendar-day': duoCalendarDay,
  'calendar-lines-pen': duoCalendarLinesPen,
  'calendar-plus': duoCalendarPlus,
  'calendar-up': duoCalendarArrowUp,
  'calendar-xmark': duoCalendarXmark,
  'caret-down': solidCaretDown,
  'caret-left': solidCaretLeft,
  'caret-right': solidCaretRight,
  'caret-up': solidCaretUp,
  'chart-bar-duo': duoChartBar,
  'chart-bar': solidChartBar,
  'chart-pie-alt': duoChartPieAlt,
  'chart-pie': solidChartPie,
  'check-circle': solidCheckCircle,
  'check-double': solidCheckDouble,
  'check-square': solidCheckSquare,
  'chevron-double-left': solidChevronDoubleLeft,
  'chevron-down': solidChevronDown,
  'chevron-left': solidChevronLeft,
  'chevron-right': solidChevronRight,
  'chevron-up': solidChevronUp,
  'circle-arrow-left': solidCircleArrowLeft,
  'clipboard-list': duoClipboardList,
  'coin-vertical': solidCoinVertical,
  'comment-code': solidCommentCode,
  'comment-empty': regularComment,
  'comment-question': solidCommentQuestion,
  'comment-xmark': solidCommentXmark,
  'comments-question-check': duoCommentsQuestionCheck,
  'comments-question': duoCommentsQuestion,
  'dashboard-calendar': solidCalendarAlt,
  'dollar-sign': solidDollarSign,
  'edit-user-profile': duoEdit,
  'education-current': solidCaretRight,
  'education-graduated': solidGraduationCap,
  'elective-settings': duoLineColumns,
  'eye-limited': duoEyeLowVision,
  'fighter-jet': solidFighterJet,
  'file-certificate': duoFileCertificate,
  'file-circle-info': duoFileCircleInfo,
  'file-contract': duoFileContract,
  'file-image': solidFileImage,
  'file-import': solidFileImport,
  'file-pdf': solidFilePdf,
  'file-spreadsheet': duoFileSpreadsheet,
  'file-text': solidFileText,
  'file-word': solidFileWord,
  'filter-past-status': duoHistory,
  'filter-status': duoClock,
  'finger-print': duoFingerprint,
  'form-templates': duoCopy,
  'game-board': sharpSolidGameBoard,
  'go-advanced': duoGear,
  'gear-code': solidGearCode,
  'google-play': brandGooglePlay,
  'graduation-cap': solidGraduationCap,
  'greater-than-equal': regularGreaterThanEqual,
  'grip-vertical': solidGripLinesVertical,
  'has-changes': solidExchangeAlt,
  'heart-circle-check': regularHeartCircleCheck,
  'hospital-user': duoHospitalUser,
  'house-laptop': solidHouseLaptop,
  'list-check': duoListCheck,
  'list-dropdown': duoListDropdown,
  'id-badge-duo': duoIdBadge,
  'id-badge': solidIdBadge,
  'input-text': duoInputText,
  'list-ol': duoListOl,
  'list-radio': duoListRadio,
  'lock-open': solidLockOpen,
  'map-marker': solidMapMarkerAlt,
  'memo-circle-info': duoMemoCircleInfo,
  'minus-square': solidMinusSquare,
  'network-empty': regularChartNetwork,
  'notification-empty': regularBell,
  'notification-settings': duoBells,
  'open-new': solidExternalLinkAlt,
  'org-settings': duoCogs,
  'paper-plane': duoPaperPlane,
  'placeholder-student': solidDuck,
  'pull-request': regularCodePullRequest,
  'quote-left': solidQuoteLeft,
  'radio-yesno': solidDotCircle,
  'road-barrier': duoRoadBarrier,
  'roles-list': duoListTree,
  'rotate-left': solidUndo,
  'rotate-right': solidRedo,
  'save-arrow-right': solidSaveArrowRight,
  'screen-share': solidDesktop,
  'search-fail': cnDuoSearchFail,
  'search-success': cnDuoSearchSuccess,
  'shield-check': solidShieldCheck,
  'sliders-simple': duoSlidersSimple,
  'slots-check': cnTrioSlotsCheck,
  'slots-exclamation': cnTrioSlotsExclamation,
  'slots-question': cnTrioSlotsQuestion,
  'slots-release': cnDuoSlotsRelease,
  'slots-stop': cnDuoSlotsStop,
  'solid-finger-print': solidFingerprint,
  'sort-alpha-asc': regularSortAlphaDown,
  'sort-alpha-desc': regularSortAlphaDownAlt,
  'sort-amount-asc': regularSortAmountDown,
  'sort-amount-desc': regularSortAmountDownAlt,
  'sort-numeric-asc': regularSortNumericDown,
  'sort-numeric-desc': regularSortNumericDownAlt,
  'sort-shapes-asc': regularSortShapesDown,
  'sort-shapes-desc': regularSortShapesDownAlt,
  'sort-size-asc': regularSortSizeDownAlt /* Font Awesome has these 2 sort columns backwards (alt vs non-alt) */,
  'sort-size-desc': regularSortSizeDown,
  'square-empty': regularSquare,
  'square-exclamation': duoSquareExclamation,
  'square-solid': solidSquare,
  'square-kanban': duoSquareKanban,
  'staff-settings': duoUsersCog,
  'my-steps': duoClipboardListCheck,
  'star-favorite': duoStarChristmas,
  'star-filled': solidStar,
  'star-thin': thinStar,
  'sync-alt': regularSyncAlt,
  'square-ellipsis-vertical': duoSquareEllipsisVertical,
  'thumbs-down': solidThumbsDown,
  'thumbs-up': solidThumbsUp,
  'thumbtack-empty': regularThumbtack,
  'thumbtack-solid': solidThumbtack,
  'trash-undo': solidTrashUndo,
  'user-check': solidUserCheck,
  'triangle-exclamation': duoTriangleExclamation,
  'user-graduate': solidUserGraduate,
  'user-robot-xmarks': duoUserRobotXmarks,
  'user-settings': duoUserCog,
  'user-tie': duoUserTie,
  'wave-pulse': solidWavePulse,
  'user-xmark': duoUserXmark,
  // 'arrow-left': solidLongArrowLeft,
}

export default {
  // Separating these out to different variables so they're easier to sort / deal with merge conflicts.
  // Makes it a little more difficult to find any icon you want to use though.
  ...matchStatuses,
  ...stepInputTypes,
  ...stepTypes,
  ...quotedKeys,
  addUser: solidUserPlus,
  agreement: duoLink,
  alert: solidExclamation,
  analytics: solidAnalytics,
  archived: solidBoxArchive,
  asterisk: solidAsterisk,
  at: solidAt,
  attachment: solidPaperclip,
  back: solidArrowLeft,
  bars: regularBarsProgress,
  blinds: duoBlinds,
  bold: solidBolt,
  bolt: duoBolt,
  bomb: duoBomb,
  book: duoBook,
  books: duoBooks,
  boxopen: duoBoxOpen,
  building: solidBuilding,
  calendar: duoCalendarAlt,
  calendarLinesPen: duoCalendarLinesPen,
  calendars: duoCalendars,
  camera: solidCamera,
  cancel: regularBan,
  check: regularCheck,
  circle: solidCircle,
  close: regularTimes,
  cloud: duoCloudArrowUp,
  code: regularCode,
  codepen: brandCodepen,
  columns: duoLineColumns,
  comment: solidComment,
  copy: regularClone,
  course: sharpSolidChalkboardUser,
  cube: duoCube,
  cubes: duoCubes,
  database: solidDatabase,
  delete: solidTrashAlt,
  demographics: duoIdCard,
  desktop: duoDesktop,
  download: solidDownload,
  duck: duoDuck,
  edit: duoEdit,
  ellipsis: solidEllipsisV,
  email: solidEnvelope,
  expand: solidExpand,
  eye: duoEye,
  facebook: brandFacebookF,
  feedback: solidCommentAltLines,
  file: duoFile,
  files: duoFiles,
  filter: solidFilter,
  filters: duoFilters,
  globe: solidGlobe,
  graduation: duoGraduationCap,
  hashtag: regularHashtag,
  help: solidQuestionCircle,
  hide: solidEyeSlash,
  history: duoHistory,
  home: solidHomeAlt,
  hospital: solidHospital,
  hourglass: solidHourglassHalf,
  image: solidImage,
  impersonate: solidMask,
  infinity: solidFaInfinity,
  info: solidInfoCircle,
  kanban: solidColumns,
  key: solidKey,
  leaf: duoLeaf,
  lightbulb: solidLightbulb,
  lighting: solidBolt,
  link: duoLink,
  linkedin: brandLinkedinIn,
  list: duoList,
  lock: duoLock,
  map: solidMap,
  markdown: brandMarkdown,
  menu: lightBars,
  merge: regularCodeMerge,
  minus: regularMinus,
  network: solidChartNetwork,
  notification: solidBell,
  overlap: regularCirclesOverlap,
  paragraph: solidParagraph,
  pause: solidPauseCircle,
  percent: solidPercent,
  phone: solidPhone,
  pig: solidPig,
  play: solidPlay,
  plus: regularPlus,
  print: solidPrint,
  profile: duoAddressCard,
  referral: duoStars,
  reopen: solidLockOpen,
  replace: duoArrowsRepeat,
  robot: solidUserRobot,
  rocket: duoRocket,
  rotation,
  save: solidSave,
  school: solidSchool,
  scroll: duoScroll,
  search: solidSearch,
  server: solidServer,
  settings: solidCog,
  shapes: duoShapes,
  share: duoShareAlt,
  shift: duoUserClock,
  sigma: solidSigma,
  signature: duoSignature,
  signout: duoSignOut,
  sitemap: duoSitemap,
  skipped: solidLongArrowRight,
  slack: brandSlack,
  slideshow: duoChartPieAlt,
  slots: cnDuoSlots,
  sort: solidSort,
  spinner: duoSpinnerThird,
  square: solidSquare,
  stamp: duoStamp,
  star: regularStar,
  stop: solidStop,
  subscription: duoShoppingCart,
  table: solidTable,
  tag: solidTag,
  tasks: solidTasks,
  time: regularClock,
  timer: solidTimer,
  times: regularTimes,
  tools: duoTools,
  twitter: brandTwitter,
  unarchive: duoBoxOpen,
  undo: solidUndo,
  unlock: solidUnlock,
  upload: solidUpload,
  user: solidUser,
  users: duoUsers,
  waived: solidLongArrowRight,
  webhook: solidRocket,
  weight: solidWeightHanging,
  wrench: solidWrench,
}
