<div bind:this={contentElem} class:hide>
  <slot
    >{#if title}<SafeHtml value={title} />{/if}</slot
  >
</div>

<script context="module">
  const top = [1, 2]
  const bottom = [1, -32]

  const defaultPopupOptions = {
    closeButton: false,

    // These are maybe a bit counterintuitive. "top" doesn't mean when the popup comes out of the top
    // of the marker; it means when the arrow comes out of the top of the popup content.
    offset: {
      top,
      'top-left': top,
      'top-right': top,
      bottom,
      'bottom-left': bottom,
      'bottom-right': bottom,
      left: [12, -20],
      right: [-12, -20],
    },
  }
</script>

<script>
  import { onDestroy, tick } from 'svelte'
  import { navigate } from 'svelte-routing'
  import SafeHtml from './SafeHtml.svelte'

  export let map
  export let mapbox

  export let name = null // Used for `data-test` attributes on marker and popup.
  export let color
  export let lat
  export let long
  export let title = '' // what shows up when you hover the marker
  export let label = null // 1 letter or number to show on the marker
  export let href = null // link when you click the marker
  export let className = null
  export let popupOptions = {}
  export let onClick = _.noop
  export let onMouseEnter = _.noop
  export let onMouseLeave = _.noop
  export let isPopUpOpen = null // true == force open (events won't close); false == force close (events won't open); null == events open/close
  export let isClusterPin = false

  let contentElem
  let coords
  let marker
  let markerElem
  let popup
  let hide = true
  let cursorIsOver = false

  const labelElem = document.createElement('span')
  labelElem.classList.add('marker-label')

  const hasSlot = !!$$props.$$slots?.default

  $: hasPopUpContent = hasSlot || title
  $: labelElem.textContent = label ?? ''
  $: long, lat, updateCoords()
  $: className, href, onClick, updateClass()
  $: color, update()
  $: name, updateDataTest()
  $: isPopUpOpen, updatePopUp()

  function updateCoords() {
    coords = [long, lat]
    marker?.setLngLat(coords)
    popup?.setLngLat(coords)
  }

  let lastClass
  function updateClass() {
    if (!markerElem) return
    const { classList } = markerElem
    if (href || onClick !== _.noop) classList.add('clickable')
    if (lastClass) classList.remove(lastClass)
    if (className != null) classList.add(className)
    if (isClusterPin) classList.add('cluster-pin')
    lastClass = className
  }

  function updateDataTest() {
    // marker data-test automatically updated via template
    const elem = popup?.getElement()
    if (!elem) return
    elem.dataset.test = `popup-${name}`
  }

  onDestroy(destroy)
  function destroy() {
    popup?.remove()
    marker?.remove()
    popup = null
    marker = null
  }

  async function openPopUp() {
    if (!hasPopUpContent) return
    if (popup && [null, true].includes(isPopUpOpen)) {
      hide = false
      // We need to wait a tick so `class:hide` gets removed; that way Mapbox will anchor the popup
      // content correctly -- otherwise its content may be cropped by the map extents.
      await tick()
      if (!popup || hide) return // Got unset while we were awaiting
      popup.setLngLat(coords).setDOMContent(contentElem).addTo(map)
    }
  }

  function closePopUp() {
    if (popup && [null, false].includes(isPopUpOpen)) popup.remove()
  }

  async function updatePopUp() {
    if (!popup) return
    if (isPopUpOpen || (isPopUpOpen == null && cursorIsOver)) {
      await openPopUp()
    } else if (isPopUpOpen === false || (isPopUpOpen == null && !cursorIsOver)) {
      closePopUp()
    }
  }

  function update() {
    destroy()

    marker = new mapbox.Marker({ color })
    popup = new mapbox.Popup({ ...defaultPopupOptions, ...popupOptions })

    updateCoords()
    marker.addTo(map)

    updateDataTest()

    markerElem = marker.getElement()
    markerElem.append(labelElem)
    markerElem.dataset.test = `marker-${name}`
    updateClass()

    markerElem.addEventListener('mouseover', () => {
      cursorIsOver = true
      openPopUp()
      onMouseEnter()
    })
    markerElem.addEventListener('mouseout', () => {
      cursorIsOver = false
      closePopUp()
      onMouseLeave()
    })
    markerElem.addEventListener('click', () => {
      if (href != null) {
        if (href.startsWith('http')) window.open(href, '_blank')
        else navigate(href)
      }
      onClick()
    })
  }
</script>
