{#if label}<label>{label}</label>{/if}
<div data-test={dataTest}>
  {#if loading && options == null}
    <Spinner />
  {:else}
    <slot name="modal-link">
      <Btn
        class="button-modal-picker-btn btn-default text-left g05{className ? ` ${className}` : ''}"
        {disabled}
        on:click={() => (disabled ? null : (open = true))}
        title={tooltip}
        bind:this={btn}
      >
        <slot name="buttonLabel" {selected}>
          <span class="button-modal-picker-text">
            {#if !selected?.length}
              {placeholder}
            {:else}
              <FriendlyList items={selected} max={2} {or} let:item punctuation>
                {#if item}{labelSelector(item)}{:else}<em>unspecified</em>{/if}
              </FriendlyList>
            {/if}
          </span>
          <Icon name="caret-down" />
        </slot>
      </Btn>
    </slot>
    {#if open}
      <Modal {lg} {xl} on:close={cancel} class={modalClass} contentClass={modalContentClass} dataTest="{dataTest}-modal">
        <span slot="title">
          <h4>{modalTitle || label || ''}</h4>
        </span>

        <div class="modal-body" data-test="{_dataTest}items">
          <slot name="top-modal-body" />

          {#if multiple && !hideSelectAll}
            <InputCheckbox alwaysPristine on:click={toggleSelectAll} name="any-selected" checked={allSelected} indeterminate={anySelected} noTwoWay>
              {#if anySelected}Clear selected <Badge count={valueTemp} unit="selected" />{:else}Select all{/if}
            </InputCheckbox>
          {/if}

          <slot />
        </div>

        {#if multiple}
          <div class="modal-footer{footerClassName ? ` ${footerClassName}` : ''}">
            <div>
              <slot name="modal-footer-message" />
            </div>
            <div class="flex-row flex-align-center g05">
              <Btn icon={confirmButtonIcon} {disabled} class="btn-primary" on:click={confirm} dataTest="{_dataTest}confirm-yes">
                {confirmButtonLabel}
              </Btn>
              <Btn icon={cancelButtonIcon} class="btn-default" on:click={cancel} autofocus dataTest="{_dataTest}confirm-no">Cancel</Btn>
            </div>
          </div>
        {/if}
      </Modal>
    {/if}
  {/if}
</div>

<script>
  import { createEventDispatcher, getContext, tick } from 'svelte'
  import { sort } from 'services/array-utils.js'
  import Badge from 'components/Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import Modal from 'components/Modal.svelte'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'

  export let modalTitle = null
  export let modalClass = null
  export let modalContentClass = null
  export let disabled = false
  export let tooltip = null
  export let label = null
  export let loading = null
  export let dataTest = null
  export let placeholder = 'None selected'
  let className = ''
  export { className as class }
  let footerClassName = 'text-center'
  export { footerClassName as footerClass }
  export let multiple = false
  export let options = null
  export let optionsForLabel = null
  export let allOptionIds = null
  export let valueSelector
  export let labelSelector
  export let value = null
  export let valueTemp = null
  export let or = false
  export let lg = false
  export let xl = false
  export let open = false
  export let allowSelectNull = false
  export let hideSelectAll = false
  export let confirmButtonLabel = 'Done'
  export let confirmButtonIcon = null
  export let cancelButtonIcon = null

  const markDirty = getContext('markDirty')
  const dispatch = createEventDispatcher()
  let btn = null
  let selected = null

  $: _dataTest = dataTest ? `${dataTest}-` : ''
  $: allValues = allOptionIds ?? options?.map(o => valueSelector(o)) ?? []
  $: anySelected = valueTemp?.length > 0
  $: allSelected = allValues && valueTemp?.length === allValues.length
  // when open, set temp value to a copy of current value
  $: if (open) valueTemp = getInitialValueTemp()
  // close immediately for single-select as soon as the user chooses something
  $: if (
    open && // it's open
    !multiple && // single-select
    (valueTemp != null || allowSelectNull) && // user has (de-)selected something
    !validator.equals(value, valueTemp) // and it's different from what's already selected
  )
    confirm()

  $: value, options, setSelected()

  async function setSelected() {
    // Need this await tick() because otherwise the button slot doesn't update despite the rest of the component updating.
    // Probably related to this issue: https://github.com/sveltejs/svelte/issues/4165
    await tick()
    if (value == null || (multiple && value.length === 0) || options == null) {
      selected = null
      return
    }
    const values = multiple ? value : [value]
    const selectedFromOptions = options.filter(o => values.includes(valueSelector(o)))
    if (selectedFromOptions.length === value.length || optionsForLabel == null) {
      selected = selectedFromOptions
      return
    }
    const missingIds = values.filter(id => !selectedFromOptions.some(o => valueSelector(o) === id))
    selected = sort(
      [...selectedFromOptions, ...missingIds.map(id => optionsForLabel.find(o => valueSelector(o) === id) ?? null)],
      labelSelector,
      true
    )
    if (!allowSelectNull) selected = selected.filter(o => o != null)
  }

  function getInitialValueTemp() {
    return value == null ? null : _.cloneDeep(value)
  }

  function confirm() {
    value = valueTemp
    dispatch('changed', value)
    open = false
    markDirty?.()
    setTimeout(() => btn?.focus?.())
  }

  function cancel() {
    valueTemp = getInitialValueTemp()
    open = false
    markDirty?.()
    setTimeout(() => btn?.focus?.())
  }

  function toggleSelectAll() {
    if (anySelected) {
      valueTemp = []
      return
    }
    valueTemp = allValues
  }

  export function focusAndOpen() {
    // Do it in this order so when the modal is closed, the button is refocused.
    focus()
    open = true
  }

  export function focusAndClose() {
    focus()
    open = false
  }

  export function focus() {
    btn?.focus?.()
  }
</script>

<style>
  .modal-footer {
    width: 100%;
    height: 70px;
    padding: 15px;
    display: grid;
    grid-template-columns: 1000fr 1fr 1000fr;
    gap: 15px;
    align-items: center;
  }
  .small-modal-footer {
    width: 100% !important;
    display: flex !important;
  }
</style>
